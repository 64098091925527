import {
  useCallback,
  // useRef,
  useState,
  Fragment,
  useEffect,
  createRef,
} from 'react'
import {TemplateForm} from 'template'
import {
  getKeyByValue,
  getParentNode,
  isEmpty,
  isFunction,
  calcAge,
  rgbToHex,
  eventTrigerInput,
} from 'utils'
import PatientInformation from './PatientInformation'
import PatExamFooters from 'template/PatientExaminationMobile/PatExamFooters'
import moment from 'moment'
import {FullErrorView} from 'molecules'
import {rgbcolors} from 'config/colors'
import {VmView, VmIcons, VmInput, VmText, VmButton, VmHeader} from 'components'
import {
  newDateWithValue,
  empty,
  triggerEventBus,
  catatLog,
  validateNumericInput,
} from 'utils/functions'
import {getAllElement, getElement} from 'utils/get-element'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {Items, defaultClassNames} from 'template/Form/HandlerForm'
import classNames from 'classnames'
import FakturRm from './FakturRm'
import PcareExamination from './PcareExamination'
import axios from 'axios'
import QueryString from 'qs'
import ButtonTindakan from './ButtonTindakan'
import {generateFlags, useCarts} from 'pages/prd-penjualan-obat-resep/utils'
import {
  mAddObatPcare,
  mDeleteObatPcare,
  mGetObatPcare,
  mSearchObatPcare,
} from './api'

const optionaltitles = ['Sembunyikan', 'Tampilkan Lebih']
const headopttitles = ['Tutup', 'Tampilkan']
const headmcu = ['Sembunyikan Entri MCU', 'Tampilkan Entri MCU']
const isRn = !!window?.ReactNativeWebView
const mode = isRn ? 'phone' : 'web'
// const mode = 'phone'
console.log('mode', mode)

const {REACT_APP_BASE_URL_DOKTER: APIDOC, REACT_APP_BASE_URL_BPJS: APIBPJS} =
  process.env

const defaultFormData = (
  data,
  options,
  disableAll = false,
  onChangeData,
  optcarts,
  customPcare,
  rdataPcare,
  handleEntriPcare,
  fieldkey,
  headmcu
) => {
  const attrTextArea = {
      typeInput: 'textarea',
      Pascal: '-',
      propsInput: {
        disabled: disableAll,
        readOnly: disableAll,
        className: 'h-20 resize-none',
      },
    },
    attrText = {
      typeInput: 'text',
      Pascal: '-',
      propsInput: {
        disabled: disableAll,
        readOnly: disableAll,
      },
    },
    attrTextOnlyNumeric = {
      typeInput: 'text',
      Pascal: '-',
      propsInput: {
        disabled: disableAll,
        readOnly: disableAll,
        onKeyDown: validateNumericInput,
      },
    },
    attrNumber = {
      typeInput: 'number',
      Pascal: '-',
      propsInput: {
        disabled: disableAll,
        readOnly: disableAll,
        min: 1,
      },
    },
    attrOpt = {
      propsInput: {
        disabled: disableAll,
        readOnly: disableAll || true,
      },
    }
  // const txtdef = m => <VmText>{m}</VmText>
  // const outersuffix = ({label = '', message = ''}) => (
  //   <VmText style={{display: 'none'}} label={label}>
  //     {message}
  //   </VmText>
  // )
  const [containerClassName, titleClassName, classItem, classSparator] =
    defaultClassNames
  const AssesmentData = {
    'diagnosa-utama': {
      inputCustom: {
        required: true,
        ariaLabel: 'diagnosa',
        value: data['diagnosa'] ?? '',
        items: options,
        placeholder: 'Pilih diagnosa',
        placeholderFilter: 'Cari Kode / Nama Diagnosa',
        className: 'flex-col',
        propsInput: {
          ...attrOpt.propsInput,
          readonly: true,
        },
        suffix: () =>
          disableAll ? (
            <></>
          ) : (
            <VmInput
              {...{
                label: `suffix-btn-diagnosa`,
                typeInput: 'button',
                style: {
                  color: data['diagnosa']
                    ? rgbcolors['danger']
                    : rgbcolors['primary'],
                },
                defaultValue: data['diagnosa'] ? 'ⓧ' : '▼',
              }}
            />
          ),
      },
    },
    'diagnosa-sekunder': {
      inputCustom: {
        ariaLabel: 'icdid',
        value: data['icdid'] ?? '',
        items: options,
        placeholder: 'Pilih diagnosa',
        placeholderFilter: 'Cari Kode / Nama Diagnosa',
        className: 'flex-col',
        propsInput: {
          ...attrOpt.propsInput,
          readonly: true,
        },
        suffix: () =>
          disableAll ? (
            <></>
          ) : (
            <VmInput
              {...{
                label: `suffix-btn-diagnosa`,
                typeInput: 'button',
                style: {
                  color: data['icdid']
                    ? rgbcolors['danger']
                    : rgbcolors['primary'],
                },
                defaultValue: data['icdid'] ? 'ⓧ' : '▼',
              }}
            />
          ),
      },
    },
    'hasil-pemeriksaan': {
      inputCustom: {
        ariaLabel: 'hasil',
        value: data['hasil'] ?? '',
        ...attrTextArea,
      },
    },
  }
  const PlanData = {
    'terapi-obat': {
      inputCustom: {
        ariaLabel: 'terapi',
        value: data['terapi'] ?? '',
        ...attrTextArea,
      },
    },
    'saran-/-anjuran': {
      inputCustom: {
        ariaLabel: 'anjuran',
        value: data['anjuran'] ?? '',
        ...attrTextArea,
      },
    },
  }
  const children = () => {
    const showFaktur = [3, 4].includes(Number(data?.kstatus))
    return (
      <Fragment>
        {/* Faktur */}
        {showFaktur && (
          <FakturRm
            kid={data?.kid}
            uid={data?.uid}
            pid={data?.pid}
            rmid={data?.id}
            rdata={rdataPcare?.rdata || data}
            lock={Number(data?.kstatus) === 4}
            disabled={disableAll}
            mode={mode}
          />
        )}
        {mode == 'phone' && customPcare && (
          <PcareExamination
            uid={rdataPcare?.uid}
            pid={rdataPcare?.pid}
            kid={rdataPcare?.kid}
            rmid={rdataPcare?.id}
            mode={mode}
            pcareVisible={true}
            rdata={rdataPcare?.rdata || {}}
            kdata={data}
            optcarts={optcarts}
            onChangeData={onChangeData}
            disabled={disableAll}
            fieldkey={fieldkey}
            headmcu={headmcu}
          />
        )}
      </Fragment>
    )
  }
  return {
    subjective: {
      'keluhan-utama': {
        inputCustom: {
          ariaLabel: 'keluhanutama',
          value: data['keluhanutama'] ?? '',
          required: true,
          ...attrTextArea,
          // propsInput: {
          //   ...attrTextArea.propsInput,
          //   maxLength: 1024,
          // },
          // prefix: () => txtdef('prefix'),
          // suffix: () => txtdef('suffix'),
          // outerprefix: () => txtdef('outerprefix'),
          // outersuffix: () => outersuffix({label: 'outersuffix'}),
        },
      },
      'keluhan-tambahan': {
        inputCustom: {
          ariaLabel: 'keluhantambahan',
          value: data['keluhantambahan'] ?? '',
          ...attrTextArea,
          // propsInput: {
          //   ...attrTextArea.propsInput,
          //   maxLength: 1024,
          // },
        },
      },
    },
    objective: {
      row1: {
        mode: 'row',
        items: {
          // Childnya
          nadi: {
            inputCustom: {
              ariaLabel: 'nadi',
              value: data['nadi'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
            },
          },
          suhu: {
            inputCustom: {
              ariaLabel: 'suhu',
              value: data['suhu'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
            },
          },
        },
      },
      pernafasan: {
        inputCustom: {
          ariaLabel: 'nafas',
          value: data['nafas'] ?? '',
          required: true,
          ...attrTextOnlyNumeric,
        },
      },
      // 'tek.-darah': {
      //   inputCustom: {
      //     ariaLabel: 'tekanandarah',
      //     value: data['tekanandarah'] ?? '',
      //     required: true,
      //     ...attrText,
      //   },
      // Ganti mode menjadi systolic & diastolic
      row2: {
        mode: 'row',
        items: {
          'tek.-darah-(systolic)': {
            inputCustom: {
              ariaLabel: 'tekanandarah_systolic',
              value: data['tekanandarah_systolic'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
            },
          },
          'tek.-darah-(diastolic)': {
            inputCustom: {
              ariaLabel: 'tekanandarah_diastolic',
              value: data['tekanandarah_diastolic'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
            },
          },
        },
      },
      row3: {
        mode: 'row',
        items: {
          'tinggi-badan': {
            inputCustom: {
              ariaLabel: 'tinggi',
              value: data['tinggi'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
              suffix: () => (
                <VmInput
                  {...{
                    label: `suffix-btn-tinggi`,
                    typeInput: 'button',
                    style: {
                      backgroundColor: `${rgbToHex(rgbcolors.secondary)}55`,
                      fontSize: '14px',
                      width: '60px',
                    },
                    readOnly: true,
                    className: 'px-3 py-1',
                    defaultValue: 'Cm',
                  }}
                />
              ),
            },
          },
          'berat-badan': {
            inputCustom: {
              ariaLabel: 'berat',
              value: data['berat'] ?? '',
              required: true,
              ...attrTextOnlyNumeric,
              suffix: () => (
                <VmInput
                  {...{
                    label: `suffix-btn-tinggi`,
                    typeInput: 'button',
                    style: {
                      backgroundColor: `${rgbToHex(rgbcolors.secondary)}55`,
                      fontSize: '14px',
                      width: '60px',
                    },
                    readOnly: true,
                    className: 'px-3 py-1',
                    defaultValue: 'Kg',
                  }}
                />
              ),
            },
          },
        },
      },
      'lingkar-perut': {
        inputCustom: {
          ariaLabel: 'perut',
          value: data['perut'] ?? '',
          required: true,
          ...attrTextOnlyNumeric,
        },
      },

      children: () => {
        return (
          <VmView className="flex justify-center items-center">
            <VmButton
              label="hide-options"
              rounded="full"
              className="btn btn-info w-56"
            >
              {optionaltitles[0]}
            </VmButton>
          </VmView>
        )
      },
      b: {
        '': {
          kepala: {
            rambut: {
              inputCustom: {
                ariaLabel: 'rambut',
                value: data['rambut'] ?? '',
                ...attrText,
              },
            },
          },
          mata: {
            pupil: {
              inputCustom: {
                ariaLabel: 'matapupil',
                value: data['matapupil'] ?? '',
                ...attrText,
              },
            },
            'sclera-:-icteric': {
              inputCustom: {
                ariaLabel: 'matasclera',
                value: data['matasclera'] ?? '',
                ...attrText,
              },
            },
          },
          telinga: {
            'membrane-thymp': {
              inputCustom: {
                ariaLabel: 'telingamembran',
                value: data['telingamembran'] ?? '',
                ...attrText,
              },
            },
            'cerumen-plug': {
              inputCustom: {
                ariaLabel: 'telingacerumen',
                value: data['telingacerumen'] ?? '',
                ...attrText,
              },
            },
          },
          hidung: {
            'polip-nasi': {
              inputCustom: {
                ariaLabel: 'hidungpolip',
                value: data['hidungpolip'] ?? '',
                ...attrText,
              },
            },
            'deviasi-septum': {
              inputCustom: {
                ariaLabel: 'hidungdeviasi',
                value: data['hidungdeviasi'] ?? '',
                ...attrText,
              },
            },
          },
          tenggorokan: {
            'Pembesaran-KGB': {
              inputCustom: {
                ariaLabel: 'tengggorokan',
                value: data['tengggorokan'] ?? '',
                ...attrText,
              },
            },
          },
          gigi: {
            carries: {
              inputCustom: {
                ariaLabel: 'gigicaries',
                value: data['gigicaries'] ?? '',
                ...attrText,
              },
            },
            'kelainan-lainnya': {
              inputCustom: {
                ariaLabel: 'gigikelainan',
                value: data['gigikelainan'] ?? '',
                ...attrText,
              },
            },
          },
          leher: {
            'pembesaran-KGB': {
              inputCustom: {
                ariaLabel: 'leher',
                value: data['leher'] ?? '',
                ...attrText,
              },
            },
          },
        },
      },
      c: {
        thorax: {
          jantung: {
            HR: {
              inputCustom: {
                ariaLabel: 'jantunghr',
                value: data['jantunghr'] ?? '',
                ...attrText,
              },
            },
            'bunyi-jantung': {
              inputCustom: {
                ariaLabel: 'jantungbunyi',
                value: data['jantungbunyi'] ?? '',
                ...attrText,
              },
            },
            'mur-mur': {
              inputCustom: {
                ariaLabel: 'jantungmur',
                value: data['jantungmur'] ?? '',
                ...attrText,
              },
            },
          },
          paru: {
            vesikuler: {
              inputCustom: {
                ariaLabel: 'paruvesikuler',
                value: data['paruvesikuler'] ?? '',
                ...attrText,
              },
            },
            ronchi: {
              inputCustom: {
                ariaLabel: 'paruronchi',
                value: data['paruronchi'] ?? '',
                ...attrText,
              },
            },
          },
        },
      },
      e: {
        abdomen: {
          palpasi: {
            'nyeri-tekan': {
              inputCustom: {
                ariaLabel: 'palpasinyeri',
                value: data['palpasinyeri'] ?? '',
                ...attrText,
              },
            },
            benjolan: {
              inputCustom: {
                ariaLabel: 'palpasibenjol',
                value: data['palpasibenjol'] ?? '',
                ...attrText,
              },
            },
            hepar: {
              inputCustom: {
                ariaLabel: 'palpasihepar',
                value: data['palpasihepar'] ?? '',
                ...attrText,
              },
            },
            lien: {
              inputCustom: {
                ariaLabel: 'palpasilien',
                value: data['palpasilien'] ?? '',
                ...attrText,
              },
            },
          },
          auskultasi: {
            'bising-usus': {
              inputCustom: {
                ariaLabel: 'auskultasi',
                value: data['auskultasi'] ?? '',
                ...attrText,
              },
            },
          },
        },
      },
      f: {
        ekstremitas: {
          atas: {
            kelainan: {
              inputCustom: {
                ariaLabel: 'ataskelainan',
                value: data['ataskelainan'] ?? '',
                ...attrText,
              },
            },
            'kebersihan-kuku': {
              inputCustom: {
                ariaLabel: 'ataskuku',
                value: data['ataskuku'] ?? '',
                ...attrText,
              },
            },
            'refleks-fisiologis': {
              inputCustom: {
                ariaLabel: 'atasfisio',
                value: data['atasfisio'] ?? '',
                ...attrText,
              },
            },
            'refleks-patologis': {
              inputCustom: {
                ariaLabel: 'ataspato',
                value: data['ataspato'] ?? '',
                ...attrText,
              },
            },
          },
          bawah: {
            kelainan: {
              inputCustom: {
                ariaLabel: 'bawahkelainan',
                value: data['bawahkelainan'] ?? '',
                ...attrText,
              },
            },
            'kebersihan-kuku': {
              inputCustom: {
                ariaLabel: 'bawahkuku',
                value: data['bawahkuku'] ?? '',
                ...attrText,
              },
            },
            'refleks-fisiologis': {
              inputCustom: {
                ariaLabel: 'bawahfisio',
                value: data['bawahfisio'] ?? '',
                ...attrText,
              },
            },
            'refleks-patologis': {
              inputCustom: {
                ariaLabel: 'bawahpato',
                value: data['bawahpato'] ?? '',
                ...attrText,
              },
            },
          },
          kulit: {
            kelainan: {
              inputCustom: {
                ariaLabel: 'kulit',
                value: data['kulit'] ?? '',
                ...attrText,
              },
            },
          },
        },
      },
    },
    assesment: {
      customView: true,
      children: index => {
        const key = `C-Assesment-${index}`
        const childData = AssesmentData
        return (
          <Fragment key={key}>
            <VmView className={classNames(classSparator, '')} />
            <VmView
              className={childData?.containerClassName || containerClassName}
            >
              <VmView
                className={classNames(
                  childData?.titleClassName || titleClassName,
                  'flex justify-between items-center'
                )}
              >
                <VmText className="">Assesment</VmText>
                {/* <VmButton
                  label="hide-card-sections"
                  rounded="full"
                  className="btn btn-info px-4 !bg-transparent"
                >
                  {headopttitles[0]}
                </VmButton> */}
              </VmView>
              {Items(childData, key)}
            </VmView>
          </Fragment>
        )
      },
    },
    plan: {
      customView: true,
      children: index => {
        const key = `C-plan-${index}`
        const childData = PlanData
        const msg = data?.lock
          ? 'Rekam Medis Terkunci'
          : disableAll
          ? 'Rekam Medis Belum Terkunci'
          : 'Kunci Rekam Medis'
        return (
          <Fragment key={key}>
            <VmView className={classNames(classSparator, '')} />
            <VmView
              className={childData?.containerClassName || containerClassName}
            >
              <VmView
                className={classNames(
                  childData?.titleClassName || titleClassName,
                  'flex justify-between items-center'
                )}
              >
                <VmText className="">Plan</VmText>
                {/* <VmButton
                  label="hide-card-sections"
                  rounded="full"
                  className="btn btn-info px-4 !bg-transparent"
                >
                  {headopttitles[0]}
                </VmButton> */}
              </VmView>
              {Items(childData, key)}
              <VmView
                className="flex items-center m-4 mb-5"
                style={{color: '#64748b'}}
                label="lock"
              >
                {data?.lock ? (
                  <VmIcons
                    name={'VmLockClosedIcon'}
                    color={rgbcolors.secondary}
                    size={30}
                    variant="outline"
                  />
                ) : disableAll ? (
                  <VmIcons
                    name={'VmLockOpenIcon'}
                    color={rgbcolors.secondary}
                    size={30}
                    variant="outline"
                  />
                ) : (
                  <VmView className="flex">
                    <VmInput
                      {...{
                        label: `suffix-checkbox-lock`,
                        typeInput: 'checkbox',
                        className: 'w-[30px] h-[30px]',
                        id: 'checkbox-lock',
                      }}
                    />
                  </VmView>
                )}
                <VmText
                  typeText="label"
                  htmlFor="checkbox-lock"
                  className="pl-2"
                >
                  {msg}
                </VmText>
              </VmView>
              <VmView
                className="flex items-center m-4 mb-5"
                style={{color: '#64748b'}}
              >
                {data?.custompcare && (
                  <>
                    <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex mb-2">
                      <VmText>Entri Kunjungan PCare</VmText>

                      <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                        <div className="col-md-9 mt-2">
                          <div className="custom-radio">
                            {[1, 2].map(value => (
                              <label
                                key={value}
                                style={{fontWeight: 'unset'}}
                                className="mr-4"
                              >
                                <input
                                  aria-label="entripcare"
                                  type="radio"
                                  value={value}
                                  name="entriPcare"
                                  style={{marginRight: '5px'}}
                                  checked={data?.entripcare == value}
                                  onChange={handleEntriPcare}
                                />
                                {value === 1 ? 'Ya' : 'Tidak'}
                              </label>
                            ))}
                          </div>
                        </div>
                      </VmView>
                    </VmView>
                  </>
                )}
              </VmView>
            </VmView>
          </Fragment>
        )
      },
    },
    // pcare: {
    //   customView: true,
    //   visible: mode == 'phone' ? true : false,
    //   children: index => {
    //     // const keybpjs = `C-pcare-${JSON.stringify(data)}`

    //     console.log(data, 'DATA NE')
    //     // console.log(customPcare,'customPcare NE');

    //     // if (mode == 'phone') {
    //     //   return (
    //     //     <PcareExamination
    //     //       uid={data?.uid}
    //     //       pid={data?.pid}
    //     //       kid={data?.kid}
    //     //       rmid={data?.id}
    //     //       mode={mode}
    //     //       pcareVisible={true}
    //     //       rdata={data?.rdata || {}}
    //     //       kdata={data}
    //     //       onChangeData={onChangeData}
    //     //     />
    //     //   )
    //     // }
    //   },
    // },
    children,
  }
}

const originalLog = console.log
console.log = function (...args) {
  const allowedLogs = ['Log:']
  if (allowedLogs.some(log => args.includes(log))) {
    originalLog.apply(console, args)
  }
}

const PatientExamination = ({
  pathname = '',
  controllers = () => {},
  navigate = () => {},
  queryParams = () => {},
  disabled = false,
  headerAttr = {enable: false},
}) => {
  // Pemeriksaan Pasien
  // const timerRef = useRef(null)
  const tFormRef = createRef()
  const currentid = queryParams.get('id')
  // console.log({currentid})
  const [avatar, setavatar] = useState('VmLAnakColorIcon')
  const [pdata, setpdata] = useState(null)
  const [data, setData] = useState({})
  const [rdata, setRdata] = useState({})
  const [datacopy, setDatacopy] = useState({})
  const [masdiagnosis, setmasdiagnosis] = useState({})
  const [optdiagnosis, setoptdiagnosis] = useState([])
  const [resdiagnosis, setresdiagnosis] = useState([])
  const [prevcarts, setprevcarts] = useState([])
  const [editable, seteditable] = useState(disabled ? !disabled : true)
  const [loading, setLoading] = useState(false)
  const [loadingrm, setloadingrm] = useState(false)
  const [errorinitial, seterrorinitial] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  const [listForm, setListForm] = useState(null)
  const [datamedicalrecord, setdatamedicalrecord] = useState(null)
  const [datacurrentmedicalrecord, setdatacurrentmedicalrecord] = useState(null)
  const [editedid, seteditedid] = useState(currentid)
  const [id, setid] = useState(currentid)
  const [kid] = useState(queryParams.get('kid'))
  const [pid] = useState(queryParams.get('pid'))
  const [dctid] = useState(queryParams.get('dctid'))
  const [today] = useState(Date.now())
  const [customPcare, setCustomPcare] = useState(false)
  const [entriPcare, setEntriPcare] = useState(1)
  const [entriMCU, setentriMCU] = useState(2)
  const [pendaftaranPcare, setPendaftaranPcare] = useState({})
  const [soaplist, setsoaplist] = useState([
    'subjective',
    'objective',
    'assesment',
    'plan',
  ])

  const fieldkey = ['kdObat'],
    [optcarts, setoptcarts] = useState({
      name: `${[
        'PEC',
        dctid || 0,
        data?.pid || 0,
        data?.kid || 0,
        data?.id || 0,
        today,
      ].join('-')}`,
      flags: 'kdObat',
      flagstok: 'sedia',
    })

  const {cart, carts, cartstoks, setcarts} = useCarts(optcarts)
  // let soaplist = ['subjective', 'objective', 'assesment', 'plan']
  // let soaplist = ['subjective', 'objective', 'assesment', 'plan', 'pcare']
  // Tab State
  const [activeTab, setActiveTab] = useState('subjective')
  const [pcareLocal, setpcareLocal] = useState(false)

  const cekCustomBpjsPcare = () => {
    const uid = dctid
    axios
      .post(
        `${APIDOC}/backend-data-registrasi/get-custom`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // console.log('cekCustomBpjsPcare', res)
        if (res?.data) {
          if (res?.data['121']) {
            setCustomPcare(true)
            onChangeData({
              entripcare: rdata?.kunpcareno ? 1 : 2,
              custompcare: true,
            })
            if (mode == 'phone') {
              setsoaplist([
                'subjective',
                'objective',
                'assesment',
                'plan',
                'pcare',
              ])
            }
          }
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Cek Custom BPJS PCare')
      })
      .finally(() => {})
  }

  const handleEntriPcare = e => {
    // console.log(e.target.value, 'handleEntriPcare')
    onChangeData({entripcare: parseInt(e.target.value)})
    setEntriPcare(parseInt(e.target.value))
  }

  const onChangeData = useCallback(
    e => {
      if (!!editable) {
        // console.log('masuk setData', {newobject: e})
        setData(t => {
          // console.log(t,'setDataT');
          // console.log(e,'setDataE');
          return Object.assign(t, e)
        })
        if (!currentid) {
          setDatacopy(t => {
            // console.log(t,'setDataCopyE');
            // console.log(e,'setDataCopyT');

            return Object.assign(t, e)
          })
        }
      }
    },
    // eslint-disable-next-line
    [data, datacopy, editable]
  )

  const validate = () => {
    // Cek validitas semua inputan, pastikan field-field yang mandatory sudah terisi semua
    // let data_satusehat = JSON.stringify({
    //   kunid_satusehat: rdata.kunid_satusehat,
    //   kunnomer: rdata.number,
    //   kuntgl: rdata.date,
    //   pasid_satusehat: rdata.pasid_satusehat,
    //   pasnama: rdata.patname,
    //   dokid_satusehat: rdata.dokid_satusehat,
    //   doknama: rdata.doknama,
    //   polid_satusehat: rdata.polid_satusehat,
    //   polnama: "Ruang Pemeriksaan",
    //   kid,dctid
    // })
    const {yesNoRef, okRef} = tFormRef.current
    // console.log(data)

    const {
      keluhanutama,
      nadi,
      suhu,
      nafas,
      tekanandarah_systolic,
      tekanandarah_diastolic,
      tinggi,
      berat,
      diagnosa,
      icdid,
    } = data
    if (empty(keluhanutama)) {
      okRef.show({message: `Harap isi field Subjective - Keluhan Utama`})
      return
    } else if (empty(nadi)) {
      okRef.show({message: `Harap isi field Objective - Nadi`})
      return
    } else if (empty(suhu)) {
      okRef.show({message: `Harap isi field Objective - Suhu`})
      return
    } else if (empty(nafas)) {
      okRef.show({message: `Harap isi field Objective - Nafas`})
      return
    } else if (empty(tekanandarah_systolic)) {
      okRef.show({
        message: `Harap isi field Objective - Tekanan Darah Systolic`,
      })
      return
    } else if (empty(tekanandarah_diastolic)) {
      okRef.show({
        message: `Harap isi field Objective - Tekanan Darah Diastolic`,
      })
      return
    } else if (empty(tinggi)) {
      okRef.show({message: `Harap isi field Objective - Tinggi Badan`})
      return
    } else if (empty(berat)) {
      okRef.show({message: `Harap isi field Objective - Berat Badan`})
      return
    } else if (empty(diagnosa)) {
      okRef.show({message: `Harap isi field Assessment - Diagnosa`})
      return
    } else if (diagnosa == icdid) {
      okRef.show({
        message: `Field Diagnosa Utama dan Diagnosa Sekunder tidak boleh sama`,
      })
      return
    }

    // Lolos, maka submit Item
    onSubmitItem()
  }

  const onSaveDataExamination = okRef => {
    // const {yesNoRef, okRef, formRef} = tFormRef.current

    const handlerOk = () => {
      // Refetch data rekam medis
      getDetailMedicalRecord({id: editedid})
      // Refetch data kunjungan
      getKunjungan()
      if (customPcare) {
        // Refetch data entri pcare
        loadPendaftaranPcare()
        loadRujukanPcare()
        loadMcuPcare()
        loadObatPcare()
      }
      navigate(0)
    }

    let kddiag3 = data?.kddiag3
      ? resdiagnosis.filter(i => i.code == data?.kddiag3)
      : []
    kddiag3 = data?.kddiag3 ? kddiag3[0]['id'] : null
    console.log('kddiag3', kddiag3)
    // return

    controllers(
      {
        ...data,
        tekanandarah: `${data.tekanandarah_systolic} / ${data.tekanandarah_diastolic}`,
        key: 'insert',
        dispatch: () => {},
        _ldata: 'mInsertPatientExamination',
        kid,
        pid,
        dctid,
        diagnosa: masdiagnosis[data?.diagnosa] ?? null,
        icdid: masdiagnosis[data?.icdid] ?? null,
        kddiag3: kddiag3 ?? null,
      },
      navigate
    )
      .then(async res => {
        // console.log('resTest', res)
        if (res?.status === 1) {
          let data_satusehat = JSON.stringify({
            kunid_satusehat: rdata.kunid_satusehat,
            kunnomer: rdata.number,
            kuntgl: moment(rdata.date).utc().format('YYYY-MM-DD HH:mm:ss'),
            pasid_satusehat: rdata.pasid_satusehat,
            pasnama: rdata.patname,
            dokid_satusehat: rdata.dokid_satusehat,
            doknama: rdata.doknama,
            polid_satusehat: rdata.polid_satusehat,
            polnama: 'Ruang Pemeriksaan',
          })

          // console.log(data_satusehat)

          let params_satusehat = {
            reg: 'dbdok',
            klid: dctid,
            appid: 'dk13_' + dctid,
            data_satusehat: data_satusehat,
            kunid: kid,
            userid: dctid,
          }

          let url_eventbus
          if (data?.lock) {
            // Kunci data sudah
            url_eventbus = 'satusehat-pemeriksaan-utama/update-finished'
          } else {
            // Masih simpan saja, Update In Progress
            url_eventbus = 'satusehat-pemeriksaan-utama/update-in-progress'
          }

          // Trigger EventBus untuk Update Encounter (Setara Kunjungan)
          triggerEventBus('pemeriksaan-utama', url_eventbus, params_satusehat)

          okRef.show(
            {
              title: 'Berhasil',
              message: 'Data berhasil disimpan.',
              type: 'success',
            },
            handlerOk,
            handlerOk
          )
        } else {
          okRef.show(
            {
              message: 'Data gagal disimpan!',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(err => {
        // console.log('err', err)
        okRef.show(
          {
            message: '[C0] Data gagal disimpan!',
          },
          () => {},
          () => {}
        )
      })
  }

  const onValidateDataPcare = okRef => {
    // const {yesNoRef, okRef} = tFormRef.current
    // console.log(okRef, 'onValidateDataPcare')
    // return

    const {
      keluhanutama,
      kesadaran,
      tenagamedis,
      statuspulang,
      jenisRujukan,
      khusus,
      catatan,
      spesialis,
      subspesialis,
      diagnosa,
      faskesrujukan,
    } = data

    let nmStatusPulang = statuspulang?.name?.toLowerCase()?.includes('rujuk')

    // console.log(statuspulang?.name,'statuspulang?.name');

    if (isEmpty(keluhanutama)) {
      okRef.show({message: `Harap isi field Subjective - Keluhan Utama`})
      return
    } else if (isEmpty(diagnosa)) {
      okRef.show({message: `Harap isi field Assesment - Diagnosa Utama`})
      return
    } else if (isEmpty(kesadaran?.id)) {
      okRef.show({message: `Harap isi field Pemeriksaan Pcare - Kesadaran`})
      return
    } else if (isEmpty(tenagamedis?.id)) {
      okRef.show({message: `Harap isi field Pemeriksaan Pcare - Tenaga Medis`})
      return
    } else if (isEmpty(statuspulang?.id)) {
      okRef.show({message: `Harap isi field Pemeriksaan Pcare - Status Pulang`})
      return
    } else if (nmStatusPulang && jenisRujukan == 1) {
      if (isEmpty(khusus?.id)) {
        okRef.show({message: `Harap isi field Pemeriksaan Pcare - Kategori`})
        return
      } else if (isEmpty(catatan)) {
        okRef.show({message: `Harap isi field Pemeriksaan Pcare - Alasan`})
        return
      } else if (isEmpty(faskesrujukan?.kdppk)) {
        okRef.show({
          message: `Harap pilih field Pemeriksaan Pcare - Faskes Rujukan`,
        })
        return
      }
    } else if (nmStatusPulang && jenisRujukan == 2) {
      if (isEmpty(spesialis?.id)) {
        okRef.show({message: `Harap isi field Pemeriksaan Pcare - Spesialis`})
        return
      } else if (isEmpty(subspesialis?.id)) {
        okRef.show({
          message: `Harap isi field Pemeriksaan Pcare - Sub Spesialis`,
        })
        return
      } else if (isEmpty(faskesrujukan?.kdppk)) {
        okRef.show({
          message: `Harap pilih field Pemeriksaan Pcare - Faskes Rujukan`,
        })
        return
      }
    }

    onSaveDataPcareExamination(okRef)
  }

  const onSaveDataPcareExamination = okRef => {
    // const {yesNoRef, okRef, formRef} = tFormRef.current
    localStorage.setItem('dataPemeriksaan', JSON.stringify(data))

    let noKartu = data?.pcaredata?.peserta?.noKartu || ''

    let dataPcare = {
      noKunjungan: rdata?.kunpcarekunjungan || null,
      // noKunjungan: null,
      noKartu: data?.pcaredata?.peserta?.noKartu || null,
      tglDaftar: data?.tglkunjunganpcare
        ? moment(data?.tglkunjunganpcare).utc().format('DD-MM-yyyy')
        : null,
      kdPoli: data?.pcaredata?.poli?.kdPoli || null,
      keluhan: data?.keluhanutama || null,
      kdSadar: data?.kesadaran?.id || null,
      sistole: data?.tekanandarah_systolic || 0,
      diastole: data?.tekanandarah_diastolic || 0,
      beratBadan: data?.berat || 0,
      tinggiBadan: data?.tinggi || 0,
      respRate: data?.nafas || 0,
      heartRate: data?.nadi || 0,
      lingkarPerut: data?.perut || 0,

      kdStatusPulang: data?.statuspulang?.id || null,
      nmStatusPulang: data?.statuspulang?.name || null,
      tglPulang: data?.tglpulangpcare
        ? moment(data?.tglpulangpcare).utc().format('DD-MM-yyyy')
        : null,
      kdDokter: data?.tenagamedis?.id || null,
      kdDiag1: data?.diagnosa ? data?.diagnosa?.split(' ')[0] : null,
      kdDiag2: data?.icdid ? data?.icdid?.split(' ')[0] : null,
      kdDiag3: data?.kddiag3 ? data?.kddiag3 : null,
      kdPoliRujukInternal: data?.faskesrujukan?.kdppk || null,

      tglEstRujuk: data?.tglberkunjungpcare
        ? moment(data?.tglberkunjungpcare).utc().format('DD-MM-yyyy')
        : null,
      kdppk: data?.faskesrujukan?.kdppk || null,
      subSpesialis: data?.subspesialis?.name || null,

      kdKhusus: data?.khusus?.id || null,
      kdSarana: data?.sarana?.id || null,
      kdSubSpesialis: data?.subspesialis?.id || null,
      catatan: data?.catatan || null,

      kdTacc: data?.tacc?.id || 0,
      alasanTacc: data?.alasantacc?.name || 0,
      anamnesa: data?.anamnesa || null,
      alergiMakan: data?.kdalergimakan || null,
      alergiUdara: data?.kdalergiudara || null,
      alergiObat: data?.kdalergiobat || null,
      kdPrognosa: data?.kdprognosa || null,
      terapiObat: data?.terapi || null,
      terapiNonObat: data?.terapinonobat || null,
      bmhp: data?.bmhp || null,
      suhu: data?.suhu || 0,
    }

    localStorage.setItem('dataPcare', JSON.stringify(dataPcare))

    axios
      .post(
        `${APIBPJS}/bpjs-jkn/add-kunjungan-pcare`,
        QueryString.stringify({
          uid: dctid,
          reg: 'dbdok',
          a: 'dk13',
          type: 'kunjungan',
          method: rdata?.kunpcarekunjungan ? 'PUT' : 'POST',
          data: dataPcare,
          jenisRujukan: data?.jenisRujukan || 2,
        })
      )
      .then(({data}) => {
        // localStorage.setItem('add-kunjungan-pcare', JSON.stringify(data))
        if (
          data?.metaData?.message?.toLocaleLowerCase().includes('created') ||
          data?.metaData?.message?.toLocaleLowerCase().includes('ok')
        ) {
          let kunpcarekunjungan =
            data.response &&
            data.response.length > 0 &&
            data?.response[0]?.field == 'noKunjungan'
              ? `${data.response[0].message}`
              : rdata?.kunpcarekunjungan

          // console.log('kunpcarekunjungan', kunpcarekunjungan)

          // if (!rdata?.kunpcarekunjungan) {
          onChangeData({
            kunpcarekunjungan: kunpcarekunjungan,
            customPcare: 121,
          })
          // }

          // setPendaftaranPcare(data?.response)
          onSaveDataExamination(okRef)
        } else {
          let countRes = data.response ? data.response.length : 1
          var message =
            data.response && data.response.length > 0
              ? `${data.response[0].field} ${data.response[0].message}`
              : ''
          let metadataMessage =
            data.metaData && data.metaData.message ? data.metaData.message : ''

          // if (countRes > 1) {
          //   let mergedMessages = ''
          //   data.response.forEach(item => {
          //     mergedMessages += `${item.field} ${item.message}, `
          //   })
          //   message = mergedMessages.trim()
          // }

          if (!message && metadataMessage) {
            message = metadataMessage
          }

          message = message
            ? message
            : `Gagal menambahkan Data Kunjungan PCare dengan No. Kartu ${noKartu}`

          okRef.show(
            {
              message: message,
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(e => {
        okRef.show(
          {
            message: '[CP1] Gagal Menyimpan Data Pemeriksaan Pcare',
          },
          () => {},
          () => {}
        )
      })
  }

  const onSubmitItem = async () => {
    const {yesNoRef, okRef, formRef} = tFormRef.current
    try {
      // console.log('tFormRef.current', tFormRef.current)
      const formArray = Object.values(formRef)
      const isInValidFormRef = formArray.filter(fl => {
        const isRequired = onRequired({target: fl})
        return isRequired
      })
      if (isInValidFormRef?.length > 0) {
        isInValidFormRef[0].focus()
        return
      }
      const handlerOk = () => {
        // Refetch data rekam medis
        getDetailMedicalRecord({id: editedid})
        // Refetch data kunjungan
        getKunjungan()
      }
      setLoading(true)
      // console.log(data)
      // return

      if (rdata?.kunpcarekunjungan) {
        const dataobat = [
          // ...prevcarts.map(
          //   async im =>
          //     await mDeleteObatPcare({
          //       input: {
          //         uid: dctid,
          //         data: {
          //           kdObatSK: im?.kdObatSK,
          //           noKunjungan: rdata?.kunpcarekunjungan,
          //         },
          //       },
          //     })
          // ),
          // ...optdiagnosis.map((im, indexIm) => {
          // }),
          // ...carts.map(
          //   async im =>
          //     await mAddObatPcare({
          //       input: {
          //         uid: dctid,
          //         data: {
          //           ...im,
          //           signa1: 1,
          //           signa2: 1,
          //           obatDPHO: true,
          //           jmlObat: im.qty,
          //           jmlPermintaan: im.qty,
          //           noKunjungan: rdata?.kunpcarekunjungan,
          //         },
          //       },
          //     })
          // ),
        ]
        await Promise.all(dataobat).then(obats => {
          console.log('obats', obats)
        })
      }
      if (customPcare && data?.entripcare == 1) {
        if (entriMCU == 2) {
          // console.log(okRef,'okRef validate');
          // return
          onValidateDataPcare(okRef)
        } else {
          okRef.show(
            {
              message: 'Data Entri MCU belum disimpan!',
            },
            () => {},
            () => {}
          )
        }
      } else {
        console.log('dataTest', data)
        let prevRekamMedis = await controllers(
          {
            key: 'detail-medical-record',
            dispatch: () => {},
            _ldata: 'mPatientDetailMedicalRecord',
            dctid,
            pid,
            kid,
          },
          navigate
        )
        console.log('value prevRekamMedis', prevRekamMedis)
        prevRekamMedis = prevRekamMedis?.data?.[0] ?? {}
        console.log('dataTest2', data)

        controllers(
          {
            ...data,
            tekanandarah: `${data.tekanandarah_systolic} / ${data.tekanandarah_diastolic}`,
            key: 'insert',
            dispatch: () => {},
            _ldata: 'mInsertPatientExamination',
            kid,
            pid,
            dctid,
            diagnosa: masdiagnosis[data?.diagnosa] ?? null,
            icdid: masdiagnosis[data?.icdid] ?? null,
          },
          navigate
        )
          .then(async res => {
            // console.log('resTest', res)
            if (res?.status === 1) {
              let data_satusehat = JSON.stringify({
                kunid_satusehat: rdata.kunid_satusehat,
                kunnomer: rdata.number,
                kuntgl: moment(rdata.date).utc().format('YYYY-MM-DD HH:mm:ss'),
                pasid_satusehat: rdata.pasid_satusehat,
                pasnama: rdata.patname,
                dokid_satusehat: rdata.dokid_satusehat,
                doknama: rdata.doknama,
                polid_satusehat: rdata.polid_satusehat,
                polnama: 'Ruang Pemeriksaan',
              })

              // console.log(data_satusehat)

              let params_satusehat = {
                reg: 'dbdok',
                klid: dctid,
                appid: 'dk13_' + dctid,
                data_satusehat: data_satusehat,
                kunid: kid,
                userid: dctid,
              }

              let url_eventbus
              if (data?.lock) {
                // Kunci data sudah
                url_eventbus = 'satusehat-pemeriksaan-utama/update-finished'
              } else {
                // Masih simpan saja, Update In Progress
                url_eventbus = 'satusehat-pemeriksaan-utama/update-in-progress'
              }

              // Trigger EventBus untuk Update Encounter (Setara Kunjungan)
              triggerEventBus(
                'pemeriksaan-utama',
                url_eventbus,
                params_satusehat
              )

              // Catat Log Dokter
              let prevtekanandarahSplit = !!prevRekamMedis['tekanandarah']
                ? prevRekamMedis['tekanandarah'].split(' / ')
                : ['', ''] // ekstrak nilai systolic & diastolicnya
              let prevtekanandarah_systolic = prevtekanandarahSplit[0]
              let prevtekanandarah_diastolic = prevtekanandarahSplit[1]
              let dataLog = {
                keterangan: `${
                  !data.lock ? 'Melakukan' : 'Mengunci'
                } pemeriksaan pasien ${rdata.patname} dengan nomor kunjungan ${
                  rdata.number
                }`,
                menu: 'Pelayanan - Pemeriksaan Pasien',
                mn_id: 16,
                url: 'prd-tra-pemeriksaan-pasien',
                before: JSON.stringify({
                  'Keluhan Utama': prevRekamMedis['keluhanutama'],
                  'Keluhan Tambahan': prevRekamMedis['keluhantambahan'],
                  Nadi: prevRekamMedis['nadi'],
                  Suhu: prevRekamMedis['suhu'],
                  Pernafasan: prevRekamMedis['nafas'],
                  'Tek. Darah (systolic)': prevtekanandarah_systolic,
                  'Tek. Darah (diastolic)': prevtekanandarah_diastolic,
                  'Tinggi Badan': prevRekamMedis['tinggi'],
                  'Berat Badan': prevRekamMedis['berat'],
                  'Lingkar Perut': prevRekamMedis['perut'],
                  'Kepala -> Rambut': prevRekamMedis['rambut'],
                  'Mata -> Pupil': prevRekamMedis['matapupil'],
                  'Mata -> Sclera: Icteric': prevRekamMedis['matasclera'],
                  'Telinga -> Membrane Thymp': prevRekamMedis['telingamembran'],
                  'Telinga -> Cerumen Plug': prevRekamMedis['telingacerumen'],
                  'Hidung -> Polip Nasi': prevRekamMedis['hidungpolip'],
                  'Hidung -> Deviasi Septum': prevRekamMedis['hidungdeviasi'],
                  'Tenggorokan -> Pembesaran KGB':
                    prevRekamMedis['tengggorokan'],
                  'Gigi -> Carries': prevRekamMedis['gigicaries'],
                  'Gigi -> Kelainan Lainnya': prevRekamMedis['gigikelainan'],
                  'Leher -> Pembesaran KGB': prevRekamMedis['leher'],
                  'Jantung -> HR': prevRekamMedis['jantunghr'],
                  'Jantung -> Bunyi Jantung': prevRekamMedis['jantungbunyi'],
                  'Jantung -> Mur Mur': prevRekamMedis['jantungmur'],
                  'Paru -> Vesikuler': prevRekamMedis['paruvesikuler'],
                  'Paru -> Ronchi': prevRekamMedis['paruronchi'],
                  'Palpasi -> Nyeri Tekan': prevRekamMedis['palpasinyeri'],
                  'Palpasi -> Benjolan': prevRekamMedis['palpasibenjol'],
                  'Palpasi -> Hepar': prevRekamMedis['palpasihepar'],
                  'Palpasi -> Lien': prevRekamMedis['palpasilien'],
                  'Auskultasi -> Bising Usus': prevRekamMedis['auskultasi'],

                  'Atas -> Kelainan': prevRekamMedis['ataskelainan'],
                  'Atas -> Kebersihan Kuku': prevRekamMedis['ataskuku'],
                  'Atas -> Refleks Fisiologis': prevRekamMedis['atasfisio'],
                  'Atas -> Refleks Patologis': prevRekamMedis['ataspato'],
                  'Bawah -> Kelainan': prevRekamMedis['bawahkelainan'],
                  'Bawah -> Kebersihan Kuku': prevRekamMedis['bawahkuku'],
                  'Bawah -> Refleks Fisiologis': prevRekamMedis['bawahfisio'],
                  'Bawah -> Refleks Patologis': prevRekamMedis['bawahpato'],
                  'Kulit -> Kelainan': prevRekamMedis['kulit'],
                  'Diagnosa Utama':
                    getKeyByValue(masdiagnosis, prevRekamMedis['diagnosa']) ??
                    '',
                  'Diagnosa Sekunder':
                    getKeyByValue(masdiagnosis, prevRekamMedis['icdid']) ?? '',
                  'Hasil Pemeriksaan': prevRekamMedis['hasil'],
                  'Terapi Obat': prevRekamMedis['terapi'],
                  Anjuran: prevRekamMedis['anjuran'],
                }),
                after: JSON.stringify({
                  'Keluhan Utama': data['keluhanutama'],
                  'Keluhan Tambahan': data['keluhantambahan'],
                  Nadi: data['nadi'],
                  Suhu: data['suhu'],
                  Pernafasan: data['nafas'],
                  'Tek. Darah (systolic)': data['tekanandarah_systolic'],
                  'Tek. Darah (diastolic)': data['tekanandarah_diastolic'],
                  'Tinggi Badan': data['tinggi'],
                  'Berat Badan': data['berat'],
                  'Lingkar Perut': data['perut'],
                  'Kepala -> Rambut': data['rambut'],
                  'Mata -> Pupil': data['matapupil'],
                  'Mata -> Sclera: Icteric': data['matasclera'],
                  'Telinga -> Membrane Thymp': data['telingamembran'],
                  'Telinga -> Cerumen Plug': data['telingacerumen'],
                  'Hidung -> Polip Nasi': data['hidungpolip'],
                  'Hidung -> Deviasi Septum': data['hidungdeviasi'],
                  'Tenggorokan -> Pembesaran KGB': data['tengggorokan'],
                  'Gigi -> Carries': data['gigicaries'],
                  'Gigi -> Kelainan Lainnya': data['gigikelainan'],
                  'Leher -> Pembesaran KGB': data['leher'],
                  'Jantung -> HR': data['jantunghr'],
                  'Jantung -> Bunyi Jantung': data['jantungbunyi'],
                  'Jantung -> Mur Mur': data['jantungmur'],
                  'Paru -> Vesikuler': data['paruvesikuler'],
                  'Paru -> Ronchi': data['paruronchi'],
                  'Palpasi -> Nyeri Tekan': data['palpasinyeri'],
                  'Palpasi -> Benjolan': data['palpasibenjol'],
                  'Palpasi -> Hepar': data['palpasihepar'],
                  'Palpasi -> Lien': data['palpasilien'],
                  'Auskultasi -> Bising Usus': data['auskultasi'],

                  'Atas -> Kelainan': data['ataskelainan'],
                  'Atas -> Kebersihan Kuku': data['ataskuku'],
                  'Atas -> Refleks Fisiologis': data['atasfisio'],
                  'Atas -> Refleks Patologis': data['ataspato'],
                  'Bawah -> Kelainan': data['bawahkelainan'],
                  'Bawah -> Kebersihan Kuku': data['bawahkuku'],
                  'Bawah -> Refleks Fisiologis': data['bawahfisio'],
                  'Bawah -> Refleks Patologis': data['bawahpato'],
                  'Kulit -> Kelainan': data['kulit'],
                  'Diagnosa Utama': data['diagnosa'],
                  'Diagnosa Sekunder': data['icdid'],
                  'Hasil Pemeriksaan': data['hasil'],
                  'Terapi Obat': data['terapi'],
                  Anjuran: data['anjuran'],
                }),
                kl_id: 19,
                // ip: publicIp,
                a: 'dk13',
                uid: dctid,
                reg: 'db',
              }
              catatLog(dataLog)
                .then(() => {})
                .catch(err => {
                  console.log('Error saat Catat Log Dokter Gan!!', err.message)
                })

              okRef.show(
                {
                  title: 'Berhasil',
                  message: 'Data berhasil disimpan.',
                  type: 'success',
                },
                handlerOk,
                handlerOk
              )
            } else {
              okRef.show(
                {
                  message: 'Data gagal disimpan!',
                },
                () => {},
                () => {}
              )
            }
          })
          .catch(err => {
            // console.log('err', err)
            okRef.show(
              {
                message: '[C0] Data gagal disimpan!',
              },
              () => {},
              () => {}
            )
          })
      }
    } catch (error) {
      // when error on proses submiting
      // console.error('Error : ', error)
      okRef.show(
        {
          message: '[C1] Data gagal disimpan!',
        },
        () => {},
        () => {}
      )
    } finally {
      setLoading(false)
    }
  }
  const onClickFooters = {
    yes: e => {
      const {yesNoRef, okRef} = tFormRef.current
      if (!loading) {
        const message = `Apakah anda yakin untuk melanjutkan proses${
          data?.lock === true ? ' dan menguncinya' : ''
        }?`
        e.stopPropagation()
        e.preventDefault()
        yesNoRef.show(
          {
            title: 'Peringatan',
            message,
            setCanceledOnTouchOutside: true,
            buttonColor: '',
          },
          validate,
          () => {}
        )
      } else {
        return
      }
    },
    no: e => {
      const {yesNoRef} = tFormRef.current
      if (!loading) {
        e.stopPropagation()
        e.preventDefault()
        yesNoRef.show(
          {
            title: 'Peringatan',
            message: 'Apakah anda yakin untuk membatalkan proses?',
            setCanceledOnTouchOutside: true,
            buttonColor: '',
          },
          () => {
            navigate(-1)
          },
          () => {}
        )
      } else {
        return
      }
    },
  }
  const footers = {
    title: {no: 'Batal', yes: 'Simpan'},
    className: {
      yes: `md:flex-none md:w-1/6 ${mode == 'web' ? 'py-2' : ''}`,
      no: `bg-white md:flex-none md:w-1/6 ${mode == 'web' ? 'py-2' : ''}`,
      container: `md:static ${
        mode == 'web' ? '!flex-row-reverse !justify-end' : 'flex-row'
      }`,
    },
    color: {
      yes: 'success',
      no: 'danger',
    },
    onClick: onClickFooters,
  }
  const onRequired = e => {
    const newError = document.createElement('p', {})
    const errorId = `error-${e?.target?.ariaLabel}`
    if (isFunction(document.getElementById(errorId)?.remove)) {
      document?.getElementById(errorId)?.remove()
    }
    var parentNode = getParentNode(e?.target)
    if (e?.target?.required && isEmpty(e?.target?.value)) {
      const title = parentNode?.firstChild?.nodeValue ?? 'this field'
      if (!parentNode?.className?.includes('input-group')) {
        // chane class to col
        if (!parentNode?.className?.includes('flex-col')) {
          parentNode.className += ' flex-col'
        }
        if (!e.target?.className?.includes(' mr-2')) {
          e.target.className = e.target.className.replace(' mr-2', '')
        }
        newError.className = errorId
        newError.id = errorId
        newError.replaceChildren(`${title} is required!`)
        e?.target.after(newError)
        return true
      } else {
        newError.className = errorId
        newError.id = errorId
        newError.replaceChildren(`${title} is required!`)
        parentNode.after(newError)
        parentNode = getParentNode(parentNode)
        if (!parentNode?.className?.includes('flex-col')) {
          parentNode.className += ' flex-col'
        }
        return true
      }
    }
    return false
  }
  const onKeyDown = (e, formRef) => {
    const cantNextAble = ['button', 'textarea']
    if (e.key === 'Enter' && !cantNextAble.includes(e.target.type)) {
      const formArray = Object.values(formRef)
      const index = formArray.indexOf(e.target)
      const isRequired = onRequired(e)
      if (isRequired) {
        return
      }
      if (isFunction(formArray[index + 1]?.focus)) {
        formArray[index + 1].focus()
        return
      }
    }
  }
  const onChangeCapture = (e, value) => {
    // console.log('Masuk sini gan, capture', {e, value, e_target: e.target})
    const {ariaLabel} = e.target

    // if(ariaLabel == 'entripcare'){
    //   console.log('entripcare', value);
    //   setEntriPcare(value)
    //   return
    // }

    if (ariaLabel) {
      onChangeData({[ariaLabel]: value})
    }
  }
  const onSglTriggerValue = (id, value = '', type = '') => {
    const getElementId = document.getElementById(id)

    // console.log(getElementId, 'getElementId')
    // console.log(type, 'type')

    if (getElementId) {
      if (type == 'text') {
        getElementId.textContent = value
      } else {
        getElementId.value = value
      }
    }
  }

  const onMbTriggerStyle = (id, property, value) => {
    // Get the element by its ID
    const element = document.getElementById(id)

    // Check if the element exists
    if (element) {
      // Get CSS property
      const propertyValue = window
        .getComputedStyle(element)
        .getPropertyValue(property)
      // console.log('Property value:', propertyValue)

      // Set CSS property
      element.style.setProperty(property, value)
    }
  }

  const onPsTriggered = (ariaLabel, value) => {
    switch (ariaLabel) {
      case 'keluhanutama':
        onSglTriggerValue(`keluhanpcare`, value)
        break
      case 'diagnosa':
        onSglTriggerValue(`diagnosapcare`, value)
        break
      case 'icdid':
        onSglTriggerValue(`diagsekunderpcare`, value)
        onMbTriggerStyle(`diagsekunderview`, 'display', 'block')
        break
      case 'nadi':
        onSglTriggerValue(`heartratepcare`, `${value} bpm`, 'text')
        break
      case 'nafas':
        onSglTriggerValue(`respratepcare`, `${value} /minute`, 'text')
        break
      case 'tekanandarah_systolic':
        onSglTriggerValue(`sistolepcare`, `${value} mmHg`, 'text')
        break
      case 'tekanandarah_diastolic':
        onSglTriggerValue(`diastolepcare`, `${value} mmHg`, 'text')
        break
      case 'tinggi':
        onSglTriggerValue(`tbpcare`, `${value} cm`, 'text')
        break
      case 'berat':
        onSglTriggerValue(`bbpcare`, `${value} kg`, 'text')
        break
      case 'perut':
        onSglTriggerValue(`lppcare`, `${value} cm`, 'text')
        break
      default:
        break
    }
  }
  const onBlur = e => {
    const isRequired = onRequired(e)
    const {ariaLabel, value} = e.target

    // console.log(ariaLabel, 'onBlur Arialabel')

    onPsTriggered(ariaLabel, value)

    if (['icdid', 'diagnosa'].includes(ariaLabel)) {
      const newClear = e.target.nextElementSibling
      if (!!e.target.value) {
        newClear.style.color = rgbcolors['danger']
        newClear.value = 'ⓧ'
      } else {
        newClear.style.color = rgbcolors['primary']
        newClear.value = '▼'
      }
    }
    if (isRequired) {
      return
    }
    onChangeData({[ariaLabel]: value})
  }
  const onFocus = e => {
    const {ariaLabel, value} = e.target
    onChangeData({[ariaLabel]: value})
    const {handleModal, modalRef} = tFormRef.current
    if (['icdid', 'diagnosa'].includes(e?.target?.ariaLabel)) {
      const newClear = e.target.nextElementSibling
      var newContent = document.createElement('div', {})
      newContent.className = 'bg-white rounded-xl pt-[3.5rem]'
      var newHeader = document.createElement('div', {})
      newHeader.className =
        'bg-white absolute flex p-4 top-0 left-0 right-0 w-full'
      var newClose = document.createElement('input', {})
      newClose.type = 'button'
      newClose.style.color = rgbcolors.danger
      newClose.style.border = 0
      newClose.value = 'ⓧ'
      newClose.onclick = () => {
        e.target.focus()
        e.target.blur()
        modalRef.close()
      }
      var newFilters = document.createElement('input', {})
      newFilters.style.width = 'inherit'
      newFilters.style.marginInline = '10px'
      newFilters.ariaLabel = 'Cari Kode / Nama Diagnosa'
      newFilters.placeholder = 'Cari Kode / Nama Diagnosa'
      newFilters.onchange = function (ef) {
        const arrData = ef.target.parentNode?.nextElementSibling?.childNodes
        const lenData = arrData.length
        for (let i = 0; i < lenData; i++) {
          const element = arrData[i]
          if (element.tagName === 'P') {
            if (
              !element.ariaLabel
                ?.toLocaleLowerCase()
                ?.includes(ef.target.value.toLocaleLowerCase())
            ) {
              element.style.display = 'none'
            } else {
              element.removeAttribute('style')
            }
          }
        }
        ef.target.focus()
      }
      var newEndOfLine = document.createElement('p', {})
      newEndOfLine.className =
        'text-center mx-1 my-2 primary-ghost PoppinsRegular'
      newEndOfLine.replaceChildren('Sudah tidak ada data!')
      newHeader.replaceChildren(...[newFilters, newClose])
      newContent.replaceChildren(
        ...[
          ...optdiagnosis.map((im, indexIm) => {
            var newChild = document.createElement('p', {})
            newChild.className =
              'btn mx-1 primary-ghost PoppinsRegular rounded-xl cursor-pointer'
            newChild.replaceChildren(im)
            newChild.ariaLabel = im
            newChild.onclick = function (em) {
              // var newClear = e.target.parentNode?.childNodes[1]
              const parentNodeE = e.target.parentNode
              if (parentNodeE?.className?.includes(' flex-col')) {
                parentNodeE.className = parentNodeE.className.replace(
                  ' flex-col',
                  ''
                )
              }
              if (!e.target?.className?.includes(' mr-2')) {
                e.target.className += ' mr-2'
              }
              e.target.value = em.target.ariaLabel
              e.target.focus()
              e.target.blur()
              modalRef.close()
            }
            return newChild
          }),
        ]
      )
      handleModal([newHeader, newContent, newEndOfLine])
      newFilters.focus({preventScroll: true})
      if (!!e.target.value) {
        newClear.style.color = rgbcolors['danger']
        newClear.value = 'ⓧ'
      } else {
        newClear.style.color = rgbcolors['primary']
        newClear.value = '▼'
      }
    }
  }
  const onClick = e => {
    const {ariaLabel, previousSibling, parentNode} = e.target
    if (ariaLabel === 'hide-mcu') {
      var prevEl = parentNode.nextSibling
      const isShown = headmcu[0] === e?.target?.innerText
      if (isShown) {
        e.target.innerText = headmcu[1]
        e.target.style.marginBottom = '1.5rem'
        setentriMCU(2)
      } else {
        e.target.innerText = headmcu[0]
        e.target.style.marginBottom = null
        setentriMCU(1)
      }
      do {
        if (prevEl) {
          if (isShown) {
            prevEl.style.display = 'none'
          } else {
            prevEl.style.display = null
          }
        }
        prevEl = prevEl?.nextSibling
      } while (prevEl)
    }
    if (ariaLabel === 'save-mcu') {
      addMcuPcare()
    }
    if (ariaLabel === 'hide-options') {
      var prevEl = parentNode.nextSibling
      const isShown = optionaltitles[0] === e?.target?.innerText
      if (isShown) {
        e.target.innerText = optionaltitles[1]
        e.target.style.marginBottom = '1.5rem'
      } else {
        e.target.innerText = optionaltitles[0]
        e.target.style.marginBottom = null
      }
      do {
        if (prevEl) {
          if (isShown) {
            prevEl.style.display = 'none'
          } else {
            prevEl.style.display = null
          }
        }
        prevEl = prevEl?.nextSibling
      } while (prevEl)
    }
    if (ariaLabel === 'hide-card-sections') {
      var prevEl = parentNode.nextSibling
      const isShown = headopttitles[0] === e?.target?.innerText
      if (isShown) {
        e.target.innerText = headopttitles[1]
      } else {
        e.target.innerText = headopttitles[0]
      }
      do {
        if (prevEl && prevEl?.ariaLabel !== 'lock') {
          if (isShown) {
            prevEl.style.display = 'none'
          } else {
            prevEl.style.display = null
          }
        }
        prevEl = prevEl?.nextSibling
      } while (prevEl)
    }
    if (['suffix-btn-icdid', 'suffix-btn-diagnosa'].includes(ariaLabel)) {
      const newClear = e.target
      if (!previousSibling?.value) {
        previousSibling?.focus()
      } else {
        newClear.style.color = rgbcolors['primary']
        previousSibling.value = ''
        newClear.value = '▼'
      }
    }
    if (ariaLabel === 'suffix-checkbox-lock') {
      onChangeData({lock: e.target?.checked})
    }
  }

  const processTekananDarah = data => {
    let tekanandarah = data.tekanandarah
    let tekanandarahSplit = tekanandarah ? tekanandarah.split(' / ') : ['', '']
    data.tekanandarah_systolic = tekanandarahSplit[0]
    data.tekanandarah_diastolic = tekanandarahSplit[1]
    return data
  }

  const getDetailMedicalRecord = useCallback(
    ({id, options = optdiagnosis, diagnosis = masdiagnosis}) => {
      setListForm({})
      var newdata = {},
        disable = false
      setLoading(true)
      if (id === 'new' || editedid === id) {
        controllers(
          {
            key: 'detail-medical-record',
            dispatch: () => {},
            _ldata: 'mPatientDetailMedicalRecord',
            dctid,
            pid,
            kid,
          },
          navigate
        )
          .then(({data: res}) => {
            if (res?.length > 0) {
              newdata = processTekananDarah(res[0])

              let tekanandarah = newdata.tekanandarah
              let tekanandarahSplit = !!tekanandarah
                ? tekanandarah.split(' / ')
                : ['', ''] // ekstrak nilai systolic & diastolicnya
              newdata.tekanandarah_systolic = tekanandarahSplit[0]
              newdata.tekanandarah_diastolic = tekanandarahSplit[1]

              setData(newdata)
              setid(newdata.id)
              seteditedid(newdata.id)
              newdata['lock'] = newdata?.kstatus === 4
              newdata['icdid'] = getKeyByValue(diagnosis, newdata.icdid) ?? ''
              newdata['uid'] = dctid
              newdata['diagnosa'] =
                getKeyByValue(diagnosis, newdata.diagnosa) ?? ''
              newdata['kddiag3'] =
                getKeyByValue(diagnosis, newdata.kddiag3) ?? ''
              setdatacurrentmedicalrecord({title: 'Sekarang', id: newdata.id})
            } else {
              setid(null)
              setData(datacopy)
              newdata = datacopy
              setdatacurrentmedicalrecord({title: 'Sekarang', id: 'new'})
            }
          })
          .catch(err => {
            // console.log('err getDetailMedicalRecord : ', err)
          })
          .finally(() => {
            setLoading(!true)

            setoptcarts({
              name: `${[
                'PEC',
                dctid || 0,
                newdata?.pid || 0,
                newdata?.kid || 0,
                newdata?.id || 0,
                today,
              ].join('-')}`,
              flags: 'kdObat',
              flagstok: 'sedia',
            })

            // console.log('newdata', newdata)
            const custom = data?.custompcare
            const rdataPcare = data
            // console.log('custompcare', data?.custompcare)

            let def = defaultFormData(
              newdata,
              options,
              disabled ? disabled : newdata['lock'],
              onChangeData,
              optcarts,
              custom,
              rdataPcare,
              handleEntriPcare,
              fieldkey,
              headmcu
            )

            if (mode == 'web') {
              // Tampilkan tab pertama yaitu subjective saja.
              setActiveTab('subjective')
              def.subjective.visible = true
              // Selain subjective dibuat false
              soaplist
                .filter(x => x !== 'subjective')
                .forEach(x => {
                  def[x].visible = false
                })
              // Children juga dikosongi a.k.a section tindakan,resep,& rujukan
              def.children = () => <></>
            }
            // console.log(def, 'def')
            setListForm(def)
            seteditable(disabled ? !disabled : !newdata['lock'])
          })
        // setid(null), setData(datacopy)
      } else {
        // console.log('getDetailMedicalRecord else')
        controllers(
          {
            key: 'detail-medical-record',
            dispatch: () => {},
            _ldata: 'mPatientDetailMedicalRecord',
            dctid,
            pid,
            id,
          },
          navigate
        )
          .then(({data: res}) => {
            if (res?.length > 0) {
              newdata = processTekananDarah(res[0])
              setData(newdata)
              disable = true
              newdata = res[0]
              newdata['lock'] = newdata?.kstatus === 4
              newdata['icdid'] = getKeyByValue(diagnosis, newdata.icdid) ?? ''
              newdata['uid'] = dctid
              newdata['diagnosa'] =
                getKeyByValue(diagnosis, newdata.diagnosa) ?? ''
              newdata['kddiag3'] =
                getKeyByValue(diagnosis, newdata.kddiag3) ?? ''
            }
          })
          .catch(err => {
            // console.log('err getDetailMedicalRecord : ', err)
          })
          .finally(() => {
            setLoading(!true)
            setid(id)

            setoptcarts({
              name: `${[
                'PEC',
                dctid || 0,
                newdata?.pid || 0,
                newdata?.kid || 0,
                newdata?.id || 0,
                today,
              ].join('-')}`,
              flags: 'kdObat',
              flagstok: 'sedia',
            })

            const custom = data?.custompcare
            const rdataPcare = data

            let def = defaultFormData(
              newdata,
              options,
              disabled ? disabled : disable,
              onChangeData,
              optcarts,
              custom,
              rdataPcare,
              handleEntriPcare,
              fieldkey,
              headmcu
            )
            if (mode == 'web') {
              // Tampilkan tab pertama yaitu subjective saja.
              setActiveTab('subjective')
              def.subjective.visible = true
              // Selain subjective dibuat false
              soaplist
                .filter(x => x !== 'subjective')
                .forEach(x => {
                  def[x].visible = false
                })
              // Children juga dikosongi a.k.a section tindakan,resep,& rujukan
              def.children = () => <></>
            }
            // console.log(def, 'def')
            setListForm(def)
            seteditable(disabled ? !disabled : false)
          })
      }

      // Setelah refetch Data Rekam medis, selected Tab kembali ke Default yaitu Subjective
      // setActiveTab('subjective')
    },
    [
      dctid,
      datacopy,
      pid,
      kid,
      editedid,
      masdiagnosis,
      optdiagnosis,
      controllers,
      navigate,
    ]
  )
  const getMedicalRecords = useCallback(() => {
    setloadingrm(true)
    controllers(
      {
        key: 'patient-medical-record',
        dispatch: () => {},
        _ldata: 'mPatientMedicalRecords',
        dctid,
        pid,
        notid: !!editedid ? [editedid] : null,
        limit: 5,
        offset: 0,
      },
      navigate
    )
      .then(({data: res}) => {
        const newkun = editedid === id ? [{title: 'Sekarang', id: 'new'}] : []

        if (res?.length > 0) {
          const newdata = res.map((im, index) => {
            var title = moment(im.date).utc().format('DD MMM YYYY')
            if (!!id && id === im.id) {
              title = 'Sekarang'
            }
            return {
              title,
              id: im.id,
            }
          })
          setdatamedicalrecord([...newkun, ...newdata])
        } else {
          setdatamedicalrecord(newkun)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setloadingrm(!true)
      })
  }, [currentid, dctid, pid, id, controllers, navigate])

  const getKunjungan = () => {
    var options = [],
      diagnosis = Object.create({}),
      errorInitial = false,
      errorMsg = ''
    setLoading(true)
    controllers(
      {
        key: 'patient-visit-info',
        dispatch: () => {},
        _ldata: 'mPatientVisitInfo',
        id: kid,
        dctid,
        pid,
      },
      navigate
    )
      .then(({data: res, message}) => {
        // console.log('res', res)
        if (res?.length > 0) {
          const rdata = res[0]

          setRdata(rdata)
          onChangeData({rdata: rdata})

          var age = calcAge(rdata?.born)
          if (rdata?.sex.substring(0, 1) === 'P') {
            setavatar('VmPAnakColorIcon')
          }
          const newdata = [
            {title: rdata?.rm},
            {title: rdata?.patname},
            {
              icon: 'VmCalendarDaysIcon',
              title: moment(rdata?.date).utc().format('DD MMM YYYY HH:mm'),
            },
            {icon: 'VmBookOpenIcon', title: rdata?.number},
            {
              icon: 'VmCakeIcon',
              // title: `${age} ${agetitle} (${rdata?.sex})`,
              title: `${age}`,
            },
            {icon: 'VmPinLocationIcon', title: rdata?.address},
          ]
          setpdata(newdata)
          // Get data diagnosa
          controllers(
            {
              key: 'search-diagnosa',
              dispatch: () => {},
              _ldata: 'mSearchDiagnosa',
              dctid,
            },
            navigate
          )
            .then(({data: res, message = `[AE2] Terjadi Kesalahan!`}) => {
              if (res?.length > 0) {
                res.forEach(im => {
                  var title = im.code.trim() + ' ' + im.name.trim()
                  diagnosis[title] = im.id
                })
                options = Object.keys(diagnosis)
                setmasdiagnosis(diagnosis)

                // console.log({optdiagnosis: options})
                setoptdiagnosis(options)
                setresdiagnosis(res)
              } else {
                errorInitial = true
                errorMsg = `Diagnosa\n${message}`
              }
            })
            .catch(err => {
              // When fail get data diagnosa
              errorInitial = true
              // console.log('err', err)
            })
            .finally(() => {
              if (!errorInitial) {
                getDetailMedicalRecord({
                  id: id ?? 'new',
                  options,
                  diagnosis,
                  lock: rdata?.status === 4,
                })
              } else {
                seterrorinitial(errorInitial)
                seterrormessage(errorMsg)
              }
              setLoading(!true)
            })
        } else {
          errorInitial = true
          errorMsg = message || `[AE1] Terjadi Kesalahan!`
          setLoading(!true)
        }
      })
      .catch(err => {
        errorInitial = true
        errorMsg = `[AE0] Terjadi Kesalahan!`
        setLoading(!true)
        // console.log('err', err)
      })
      .finally(() => {
        if (errorInitial) {
          seterrorinitial(errorInitial)
          seterrormessage(errorMsg)
        }
      })
  }

  const loadPendaftaranPcare = async () => {
    const uid = dctid
    const ldataMedis = data
    const lname = 'dataBpjs_pendaftaran'
    const dataBpjs = getLclStorage(lname, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    let clonedListForm = listForm

    // console.log(ldataMedis, 'ldataMedis')

    if (ldataBpjs && ldataBpjs.length > 0 && pcareLocal) {
      setPendaftaranPcare(ldataBpjs)

      const keluhan = ldataBpjs?.keluhan || ''

      onChangeData({
        ['pcareloaded']: true,
        ['keluhanutama']: ldataMedis?.keluhanutama || keluhan,
        ['tinggi']: ldataMedis?.tinggi || ldataBpjs?.tinggiBadan,
        ['berat']: ldataMedis?.berat || ldataBpjs?.beratBadan,
        ['tekanandarah_systolic']:
          ldataMedis?.tekanandarah_systolic || ldataBpjs?.sistole,
        ['tekanandarah_diastolic']:
          ldataMedis?.tekanandarah_diastolic || ldataBpjs?.diastole,
        ['nafas']: ldataMedis?.nafas || ldataBpjs?.respRate,
        ['nadi']: ldataMedis?.nadi || ldataBpjs?.heartRate,
        pcaredata: ldataBpjs,
        kdProvider: ldataBpjs?.providerPelayanan?.kdProvider || '',
        entripcare: customPcare && ldataBpjs ? 1 : 2,
        custompcare: customPcare,
        rdata: rdata,
      })

      if (clonedListForm?.subjective) {
        clonedListForm.subjective['keluhan-utama'].inputCustom.value =
          ldataBpjs?.keluhan
      }
    } else {
      if (rdata?.kunpcareno && rdata?.date && clonedListForm) {
        await axios
          .post(
            `${APIBPJS}/bpjs-jkn/get-pendaftaran-pcare`,
            QueryString.stringify({
              uid: uid,
              reg: 'dbdok',
              a: 'dk13',
              type: 'pendaftaran',
              noUrut: rdata?.kunpcareno,
              tglDaftar: moment(rdata?.date).utc().format('DD-MM-yyyy'),
              // noUrut: 'A1',
              // tglDaftar: '12-01-2024',
            })
          )
          .then(({data}) => {
            console.log(data, 'getDataPendaftaran')
            if (data?.metaData?.code == 200) {
              setPendaftaranPcare(data?.response)

              var keluhan = data?.response?.keluhan || ''
              keluhan = keluhan != 'null' ? keluhan : ''

              if (ldataMedis?.keluhanutama) {
                onChangeData({
                  pcaredata: data?.response,
                  kdProvider:
                    data?.response?.providerPelayanan?.kdProvider ||
                    ldataMedis?.kdProvider,
                  entripcare: customPcare && data?.response ? 1 : 2,
                  custompcare: customPcare,
                  rdata: rdata,
                })
                return
              }

              onChangeData({
                ['pcareloaded']: true,
                ['keluhanutama']: ldataMedis?.keluhanutama || keluhan,
                ['tinggi']: ldataMedis?.tinggi || data?.response?.tinggiBadan,
                ['berat']: ldataMedis?.berat || data?.response?.beratBadan,
                ['tekanandarah_systolic']:
                  ldataMedis?.tekanandarah_systolic || data?.response?.sistole,
                ['tekanandarah_diastolic']:
                  ldataMedis?.tekanandarah_diastolic ||
                  data?.response?.diastole,
                ['nafas']: ldataMedis?.nafas || data?.response?.respRate,
                ['perut']: ldataMedis?.perut || data?.response?.lingkarPerut,
                ['nadi']: ldataMedis?.nadi || data?.response?.heartRate,
                pcaredata: data?.response,
                kdProvider:
                  data?.response?.providerPelayanan?.kdProvider ||
                  ldataMedis?.kdProvider,
                entripcare: customPcare && data?.response ? 1 : 2,
                custompcare: customPcare,
                rdata: rdata,
              })

              if (clonedListForm?.subjective) {
                clonedListForm.subjective['keluhan-utama'].inputCustom.value =
                  keluhan
              }

              if (clonedListForm?.objective) {
                clonedListForm.objective['row1'].items.nadi.inputCustom.value =
                  ldataMedis?.nadi || data?.response?.heartRate
                clonedListForm.objective.pernafasan.inputCustom.value =
                  ldataMedis?.nafas || data?.response?.respRate
                clonedListForm.objective['lingkar-perut'].inputCustom.value =
                  ldataMedis?.perut || data?.response?.lingkarPerut
                clonedListForm.objective['row2'].items[
                  'tek.-darah-(systolic)'
                ].inputCustom.value =
                  ldataMedis?.tekanandarah_systolic || data?.response?.sistole
                clonedListForm.objective['row2'].items[
                  'tek.-darah-(diastolic)'
                ].inputCustom.value =
                  ldataMedis?.tekanandarah_diastolic || data?.response?.diastole
                clonedListForm.objective['row3'].items[
                  'tinggi-badan'
                ].inputCustom.value =
                  ldataMedis?.tinggi || data?.response?.tinggiBadan
                clonedListForm.objective['row3'].items[
                  'berat-badan'
                ].inputCustom.value =
                  ldataMedis?.berat || data?.response?.beratBadan
              }

              setLclStorage(lname, JSON.stringify(data?.response), {type: 2})
            } else {
              // console.log('Data Pendaftaran tidak ditemukan')
              onChangeData({
                entripcare: 2,
                entripcaredisable: true,
                custompcare: customPcare,
                rdata: rdata,
              })
            }
          })
          .catch(e => {
            // console.log(e, 'Gagal mengambil Data Pendaftaran')
            onChangeData({
              entripcare: 2,
              entripcaredisable: true,
              custompcare: customPcare,
              rdata: rdata,
            })
          })
      }
    }
  }

  const loadRujukanPcare = () => {
    const uid = dctid
    const kdata = data
    const lname = 'dataBpjs_kunjungan'
    const dataBpjs = getLclStorage(lname, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    // let clonedListForm = listForm

    if (ldataBpjs && ldataBpjs.length > 0 && pcareLocal) {
      console.log(ldataBpjs, 'ldataBpjsKunjungan')

      const nmTacc = ldataBpjs?.tacc?.nmTacc || 'Tanpa TACC'
      const alasanTacc = ldataBpjs?.tacc?.alasanTacc || ''
      var kdTacc = '-1'

      switch (nmTacc) {
        case 'Time':
          kdTacc = '1'
          break
        case 'Age':
          kdTacc = '2'
          break
        case 'Complication':
          kdTacc = '3'
          break
        case 'Comorbidity':
          kdTacc = '4'
          break
        default:
          kdTacc = '-1'
          break
      }

      onChangeData({
        jenisRujukan: kdata?.kdkhusus ? 1 : 2,
        tenagamedis: {
          id: ldataBpjs?.dokter?.kdDokter || null,
          name: ldataBpjs?.dokter?.nmDokter || null,
        },
        tacc: {
          id: kdTacc,
          name: nmTacc,
        },
        alasanTacc: {
          id: alasanTacc,
          name: alasanTacc,
        },
        catatan: ldataBpjs?.catatanRujuk || '',
        faskesrujukan: {
          id: ldataBpjs?.ppkRujuk?.kdPPK || null,
          name: ldataBpjs?.ppkRujuk?.nmPPK || null,
        },
        tglkunjunganpcare: ldataBpjs?.tglKunjungan
          ? newDateWithValue(ldataBpjs?.tglKunjungan)
          : new Date(),
        tglpulangpcare: kdata?.pcaretglpulang
          ? new Date(kdata?.pcaretglpulang)
          : new Date(),
        tglberkunjungpcare: ldataBpjs?.tglEstRujuk
          ? newDateWithValue(ldataBpjs?.tglEstRujuk)
          : new Date(),
        noKunjungan: rdata?.kunpcarekunjungan || '',
        kdProvider: ldataBpjs?.ppk?.kdPPK || '',
        tglPelayanan: ldataBpjs?.tglKunjungan
          ? newDateWithValue(ldataBpjs?.tglKunjungan)
          : new Date(),
      })
    } else {
      if (rdata?.kunpcarekunjungan) {
        axios
          .post(
            `${APIBPJS}/bpjs-jkn/get-rujukan-pcare`,
            QueryString.stringify({
              uid: uid,
              reg: 'dbdok',
              a: 'dk13',
              type: 'kunjungan',
              noKunjungan: rdata?.kunpcarekunjungan,
            })
          )
          .then(({data}) => {
            console.log(data, 'getDataKunjungan')
            if (data?.metaData?.code == 200) {
              const ldata = data?.response || {}

              const nmTacc = ldata?.tacc?.nmTacc || 'Tanpa TACC'
              const alasanTacc = ldata?.tacc?.alasanTacc || ''
              var kdTacc = '-1'

              switch (nmTacc) {
                case 'Time':
                  kdTacc = '1'
                  break
                case 'Age':
                  kdTacc = '2'
                  break
                case 'Complication':
                  kdTacc = '3'
                  break
                case 'Comorbidity':
                  kdTacc = '4'
                  break
                default:
                  kdTacc = '-1'
                  break
              }

              onChangeData({
                jenisRujukan: kdata?.kdkhusus ? 1 : 2,
                tenagamedis: {
                  id: ldata?.dokter?.kdDokter || null,
                  name: ldata?.dokter?.nmDokter || null,
                },
                tacc: {
                  id: kdTacc,
                  name: nmTacc,
                },
                alasanTacc: {
                  id: alasanTacc,
                  name: alasanTacc,
                },
                catatan: ldata?.catatanRujuk || '',
                faskesrujukan: {
                  kdppk: ldata?.ppkRujuk?.kdPPK || null,
                  nmppk: ldata?.ppkRujuk?.nmPPK || null,
                },
                tglkunjunganpcare: ldata?.tglKunjungan
                  ? newDateWithValue(ldata?.tglKunjungan)
                  : new Date(),
                tglpulangpcare: kdata?.pcaretglpulang
                  ? new Date(kdata?.pcaretglpulang)
                  : new Date(),
                tglberkunjungpcare: ldata?.tglEstRujuk
                  ? newDateWithValue(ldata?.tglEstRujuk)
                  : new Date(),
                noKunjungan: rdata?.kunpcarekunjungan || '',
                kdProvider: ldata?.ppk?.kdPPK || kdata?.kdProvider,
                tglPelayanan: ldata?.tglKunjungan
                  ? newDateWithValue(ldata?.tglKunjungan)
                  : new Date(),
              })

              setLclStorage(lname, JSON.stringify(data?.response), {type: 2})
            } else {
              onChangeData({
                jenisRujukan: kdata?.kdkhusus ? 1 : 2,
                tglpulangpcare: kdata?.pcaretglpulang
                  ? new Date(kdata?.pcaretglpulang)
                  : new Date(),
                tacc: {
                  id: '-1',
                  name: 'Tanpa TACC',
                },
                noKunjungan: rdata?.kunpcarekunjungan || '',
                kdProvider:
                  kdata?.pcaredata?.providerPelayanan?.kdProvider ||
                  kdata?.kdProvider,
                tglPelayanan: new Date(),
              })
              // console.log('Data Kunjungan Pcare tidak ditemukan')
            }
          })
          .catch(e => {
            // console.log(e, 'Gagal mengambil Data Kunjungan Pcare')
          })
      }
    }
  }

  const loadDataDokter = () => {
    const uid = dctid
    const lname = 'dokter'
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    axios
      .post(
        `${APIBPJS}/bpjs-jkn/get-data-pcare`,
        QueryString.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: lname,
        })
      )
      .then(({data}) => {
        if (data?.metaData?.code == 200) {
          setLclStorage(
            `dataBpjs_${lname}`,
            JSON.stringify(data?.response?.list),
            {type: 2}
          )
        } else {
          // console.log('Data tidak ditemukan')
        }
      })
      .catch(e => {
        // console.log('Gagal mengambil data')
      })
  }

  const loadKesadaran = async () => {
    const lname = 'dataBpjs_kesadaran'
    const uid = dctid
    const kdata = data
    const dataKesadaran = getLclStorage(lname, {type: 2})
    const ldataKesadaran = dataKesadaran ? JSON.parse(dataKesadaran) : []
    if (ldataKesadaran && ldataKesadaran.length > 0) {
      if (kdata?.kdkesadaran) {
        const kdkesadaran = await ldataKesadaran.find(
          item => item.kdSadar === kdata?.kdkesadaran
        )
        // setkesadaran({id: kdkesadaran?.kdSadar, name: kdkesadaran?.nmSadar})
        onChangeData({
          kesadaran: {id: kdkesadaran.kdSadar, name: kdkesadaran.nmSadar},
          kdkesadaran: kdkesadaran.kdSadar || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'kesadaran',
          })
        )
        .then(({data}) => {
          // console.log(data, 'loadKesadaran')
          if (data?.metaData?.code == 200) {
            // setDataKesadaran(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdkesadaran) {
              const kdkesadaran = ldata?.find(
                item => item.kdSadar === kdata?.kdkesadaran
              )

              // setkesadaran({
              //   id: kdkesadaran?.kdSadar,
              //   name: kdkesadaran?.nmSadar,
              // })

              onChangeData({
                kesadaran: {id: kdkesadaran.kdSadar, name: kdkesadaran.nmSadar},
                kdkesadaran: kdkesadaran.kdSadar || '',
              })
            }

            setLclStorage(lname, JSON.stringify(data?.response?.list), {
              type: 2,
            })
          } else {
            // console.log(
            //   data?.metaData?.message ?? 'Data Kesadaran tidak ditemukan'
            // )
          }
        })
        .catch(e => {
          // console.log('Gagal mengambil Data Kesadaran')
        })
    }
  }

  const loadStatusPulang = async () => {
    const lname = 'statuspulang'
    const uid = dctid
    const kdata = data
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    // console.log(dataStatusPulang, 'dataStatusPulang')
    if (ldataBpjs && ldataBpjs.length > 0) {
      // setDataStatusPulang(ldataBpjs)

      if (kdata?.kdstatuspulang) {
        const findData = ldataBpjs?.find(
          item => item.kdStatusPulang === kdata?.kdstatuspulang
        )

        // setstatuspulang({
        //   id: findData?.kdStatusPulang,
        //   name: findData?.nmStatusPulang,
        // })

        onChangeData({
          statuspulang: {
            id: findData?.kdStatusPulang,
            name: findData?.nmStatusPulang,
          },
          kdstatuspulang: findData?.kdStatusPulang || '',
        })
      }
      // console.log(kdata?.kdstatuspulang, 'kdata?.kdstatuspulang')
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
          })
        )
        .then(({data}) => {
          // console.error(data, 'loadStatusPulang')
          if (data?.metaData?.code == 200) {
            // setDataStatusPulang(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdstatuspulang) {
              const findData = ldata?.find(
                item => item.kdStatusPulang === kdata?.kdstatuspulang
              )

              // setstatuspulang({
              //   id: findData?.kdStatusPulang,
              //   name: findData?.nmStatusPulang,
              // })

              onChangeData({
                statuspulang: {
                  id: findData?.kdStatusPulang,
                  name: findData?.nmStatusPulang,
                },
                kdstatuspulang: findData?.kdStatusPulang || '',
              })
            }

            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            // console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          // console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataSpesialis = async () => {
    const lname = 'spesialis'
    const uid = dctid
    const kdata = data
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      // setDataSpesialis(ldataBpjs)
      if (kdata?.kdspesialis) {
        const findData = await ldataBpjs?.find(
          item => item.kdSpesialis === kdata?.kdspesialis
        )

        // setspesialis({id: findData?.kdSpesialis, name: findData?.nmSpesialis})

        onChangeData({
          spesialis: {
            id: findData?.kdSpesialis,
            name: findData?.nmSpesialis,
          },
          kdspesialis: findData?.kdSpesialis || '',
        })
      }

      // console.log(ldataBpjs, 'ldataBpjs')
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: lname,
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            // setDataSpesialis(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdspesialis) {
              const findData = ldata?.find(
                item => item.kdSpesialis === kdata?.kdspesialis
              )

              // setspesialis({
              //   id: findData?.kdSpesialis,
              //   name: findData?.nmSpesialis,
              // })

              onChangeData({
                spesialis: {
                  id: findData?.kdSpesialis,
                  name: findData?.nmSpesialis,
                },
                kdspesialis: findData?.kdSpesialis || '',
              })
            }
            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            // console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          // console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataSarana = async () => {
    const lname = 'sarana'
    const uid = dctid
    const kdata = data
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      // setDataSarana(ldataBpjs)
      if (kdata?.kdsarana) {
        const findData = await ldataBpjs?.find(
          item => item.kdSarana === kdata?.kdsarana
        )

        // setsarana({id: findData?.kdSarana, name: findData?.nmSarana})

        onChangeData({
          sarana: {
            id: findData?.kdSarana,
            name: findData?.nmSarana,
          },
          kdsarana: findData?.kdSarana || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: 'spesialis/sarana',
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            // setDataSarana(data?.response?.list)
            const ldata = data?.response?.list
            if (kdata?.kdsarana) {
              const findData = ldata?.find(
                item => item.kdSarana === kdata?.kdsarana
              )

              // setsarana({id: findData?.kdSarana, name: findData?.nmSarana})

              onChangeData({
                sarana: {
                  id: findData?.kdSarana,
                  name: findData?.nmSarana,
                },
                kdsarana: findData?.kdSarana || '',
              })
            }
            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            // console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          // console.log('Gagal mengambil data')
        })
    }
  }

  const loadDataKhusus = async () => {
    const lname = 'khusus'
    const uid = dctid
    const kdata = data
    const dataBpjs = getLclStorage(`dataBpjs_${lname}`, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []
    if (ldataBpjs && ldataBpjs.length > 0) {
      // setDataKhusus(ldataBpjs)
      if (kdata?.kdkhusus) {
        const findData = await ldataBpjs?.find(
          item => item.kdKhusus === kdata?.kdkhusus
        )

        // setkhusus({id: findData?.kdKhusus, name: findData?.nmKhusus})

        onChangeData({
          khusus: {
            id: findData?.kdKhusus,
            name: findData?.nmKhusus,
          },
          kdkhusus: findData?.kdKhusus || '',
        })
      }
    } else {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/get-data-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: lname,
            url: 'spesialis/khusus',
          })
        )
        .then(({data}) => {
          // console.log(data, 'getDataBpjs')
          if (data?.metaData?.code == 200) {
            // setDataKhusus(data?.response?.list)
            const ldata = data?.response?.list

            if (kdata?.kdkhusus) {
              const findData = ldata?.find(
                item => item.kdKhusus === kdata?.kdkhusus
              )

              // setkhusus({id: findData?.kdKhusus, name: findData?.nmKhusus})

              onChangeData({
                khusus: {
                  id: findData?.kdKhusus,
                  name: findData?.nmKhusus,
                },
                kdkhusus: findData?.kdKhusus || '',
              })
            }

            setLclStorage(
              `dataBpjs_${lname}`,
              JSON.stringify(data?.response?.list),
              {type: 2}
            )
          } else {
            // console.log('Data tidak ditemukan')
          }
        })
        .catch(e => {
          // console.log('Gagal mengambil data')
        })
    }
  }

  const loadMcuPcare = async () => {
    const uid = dctid
    const kdata = data
    const lname = 'dataBpjs_mcu'
    const dataBpjs = getLclStorage(lname, {type: 2})
    const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    if (ldataBpjs && ldataBpjs.length > 0 && pcareLocal) {
      console.log(ldataBpjs, 'ldataBpjsMcu')

      onChangeData({
        kdMCU: ldataBpjs?.kdMCU ? ldataBpjs?.kdMCU : 0,
        noKunjungan: ldataBpjs?.noKunjungan ? ldataBpjs?.noKunjungan : '',
        kdProvider: ldataBpjs?.kdProvider ? ldataBpjs?.kdProvider : '',
        tglPelayanan: ldataBpjs?.tglPelayanan
          ? newDateWithValue(ldataBpjs?.tglPelayanan)
          : new Date(),
        tekananDarahSistole: ldataBpjs?.tekananDarahSistole
          ? ldataBpjs?.tekananDarahSistole
          : 0,
        tekananDarahDiastole: ldataBpjs?.tekananDarahDiastole
          ? ldataBpjs?.tekananDarahDiastole
          : 0,
        radiologiFoto: ldataBpjs?.radiologiFoto
          ? ldataBpjs?.radiologiFoto
          : null,
        darahRutinHemo: ldataBpjs?.darahRutinHemo
          ? ldataBpjs?.darahRutinHemo
          : 0,
        darahRutinLeu: ldataBpjs?.darahRutinLeu ? ldataBpjs?.darahRutinLeu : 0,
        darahRutinErit: ldataBpjs?.darahRutinErit
          ? ldataBpjs?.darahRutinErit
          : 0,
        darahRutinLaju: ldataBpjs?.darahRutinLaju
          ? ldataBpjs?.darahRutinLaju
          : 0,
        darahRutinHema: ldataBpjs?.darahRutinHema
          ? ldataBpjs?.darahRutinHema
          : 0,
        darahRutinTrom: ldataBpjs?.darahRutinTrom
          ? ldataBpjs?.darahRutinTrom
          : 0,
        lemakDarahHDL: ldataBpjs?.lemakDarahHDL ? ldataBpjs?.lemakDarahHDL : 0,
        lemakDarahLDL: ldataBpjs?.lemakDarahLDL ? ldataBpjs?.lemakDarahLDL : 0,
        lemakDarahChol: ldataBpjs?.lemakDarahChol
          ? ldataBpjs?.lemakDarahChol
          : 0,
        lemakDarahTrigli: ldataBpjs?.lemakDarahTrigli
          ? ldataBpjs?.lemakDarahTrigli
          : 0,
        gulaDarahSewaktu: ldataBpjs?.gulaDarahSewaktu
          ? ldataBpjs?.gulaDarahSewaktu
          : 0,
        gulaDarahPuasa: ldataBpjs?.gulaDarahPuasa
          ? ldataBpjs?.gulaDarahPuasa
          : 0,
        gulaDarahPostPrandial: ldataBpjs?.gulaDarahPostPrandial
          ? ldataBpjs?.gulaDarahPostPrandial
          : 0,
        gulaDarahHbA1c: ldataBpjs?.gulaDarahHbA1c
          ? ldataBpjs?.gulaDarahHbA1c
          : 0,
        fungsiHatiSGOT: ldataBpjs?.fungsiHatiSGOT
          ? ldataBpjs?.fungsiHatiSGOT
          : 0,
        fungsiHatiSGPT: ldataBpjs?.fungsiHatiSGPT
          ? ldataBpjs?.fungsiHatiSGPT
          : 0,
        fungsiHatiGamma: ldataBpjs?.fungsiHatiGamma
          ? ldataBpjs?.fungsiHatiGamma
          : 0,
        fungsiHatiProtKual: ldataBpjs?.fungsiHatiProtKual
          ? ldataBpjs?.fungsiHatiProtKual
          : 0,
        fungsiHatiAlbumin: ldataBpjs?.fungsiHatiAlbumin
          ? ldataBpjs?.fungsiHatiAlbumin
          : 0,
        fungsiGinjalCrea: ldataBpjs?.fungsiGinjalCrea
          ? ldataBpjs?.fungsiGinjalCrea
          : 0,
        fungsiGinjalUreum: ldataBpjs?.fungsiGinjalUreum
          ? ldataBpjs?.fungsiGinjalUreum
          : 0,
        fungsiGinjalAsam: ldataBpjs?.fungsiGinjalAsam
          ? ldataBpjs?.fungsiGinjalAsam
          : 0,
        fungsiJantungABI: ldataBpjs?.fungsiJantungABI
          ? ldataBpjs?.fungsiJantungABI
          : 0,
        fungsiJantungEKG: ldataBpjs?.fungsiJantungEKG
          ? ldataBpjs?.fungsiJantungEKG
          : null,
        fungsiJantungEcho: ldataBpjs?.fungsiJantungEcho
          ? ldataBpjs?.fungsiJantungEcho
          : null,
        funduskopi: ldataBpjs?.funduskopi ? ldataBpjs?.funduskopi : null,
        pemeriksaanLain: ldataBpjs?.pemeriksaanLain
          ? ldataBpjs?.pemeriksaanLain
          : null,
        keterangan: ldataBpjs?.keterangan ? ldataBpjs?.keterangan : null,
      })
    } else {
      if (rdata?.kunpcarekunjungan) {
        await axios
          .post(
            `${APIBPJS}/bpjs-jkn/mcu-pcare`,
            QueryString.stringify({
              uid: uid,
              reg: 'dbdok',
              a: 'dk13',
              type: 'mcu',
              data: {noKunjungan: rdata?.kunpcarekunjungan},
            })
          )
          .then(({data}) => {
            // console.log(data, 'getDataMCU')
            if (data?.metaData?.code == 200) {
              const ldata = data?.response?.list[0] || {}

              onChangeData({
                kdMCU: ldata?.kdMCU ? ldata?.kdMCU : 0,
                noKunjungan: ldata?.noKunjungan ? ldata?.noKunjungan : '',
                kdProvider: ldata?.kdProvider ? ldata?.kdProvider : '',
                tglPelayanan: ldata?.tglPelayanan
                  ? newDateWithValue(ldata?.tglPelayanan)
                  : new Date(),
                tekananDarahSistole: ldata?.tekananDarahSistole
                  ? ldata?.tekananDarahSistole
                  : 0,
                tekananDarahDiastole: ldata?.tekananDarahDiastole
                  ? ldata?.tekananDarahDiastole
                  : 0,
                radiologiFoto: ldata?.radiologiFoto
                  ? ldata?.radiologiFoto
                  : null,
                darahRutinHemo: ldata?.darahRutinHemo
                  ? ldata?.darahRutinHemo
                  : 0,
                darahRutinLeu: ldata?.darahRutinLeu ? ldata?.darahRutinLeu : 0,
                darahRutinErit: ldata?.darahRutinErit
                  ? ldata?.darahRutinErit
                  : 0,
                darahRutinLaju: ldata?.darahRutinLaju
                  ? ldata?.darahRutinLaju
                  : 0,
                darahRutinHema: ldata?.darahRutinHema
                  ? ldata?.darahRutinHema
                  : 0,
                darahRutinTrom: ldata?.darahRutinTrom
                  ? ldata?.darahRutinTrom
                  : 0,
                lemakDarahHDL: ldata?.lemakDarahHDL ? ldata?.lemakDarahHDL : 0,
                lemakDarahLDL: ldata?.lemakDarahLDL ? ldata?.lemakDarahLDL : 0,
                lemakDarahChol: ldata?.lemakDarahChol
                  ? ldata?.lemakDarahChol
                  : 0,
                lemakDarahTrigli: ldata?.lemakDarahTrigli
                  ? ldata?.lemakDarahTrigli
                  : 0,
                gulaDarahSewaktu: ldata?.gulaDarahSewaktu
                  ? ldata?.gulaDarahSewaktu
                  : 0,
                gulaDarahPuasa: ldata?.gulaDarahPuasa
                  ? ldata?.gulaDarahPuasa
                  : 0,
                gulaDarahPostPrandial: ldata?.gulaDarahPostPrandial
                  ? ldata?.gulaDarahPostPrandial
                  : 0,
                gulaDarahHbA1c: ldata?.gulaDarahHbA1c
                  ? ldata?.gulaDarahHbA1c
                  : 0,
                fungsiHatiSGOT: ldata?.fungsiHatiSGOT
                  ? ldata?.fungsiHatiSGOT
                  : 0,
                fungsiHatiSGPT: ldata?.fungsiHatiSGPT
                  ? ldata?.fungsiHatiSGPT
                  : 0,
                fungsiHatiGamma: ldata?.fungsiHatiGamma
                  ? ldata?.fungsiHatiGamma
                  : 0,
                fungsiHatiProtKual: ldata?.fungsiHatiProtKual
                  ? ldata?.fungsiHatiProtKual
                  : 0,
                fungsiHatiAlbumin: ldata?.fungsiHatiAlbumin
                  ? ldata?.fungsiHatiAlbumin
                  : 0,
                fungsiGinjalCrea: ldata?.fungsiGinjalCrea
                  ? ldata?.fungsiGinjalCrea
                  : 0,
                fungsiGinjalUreum: ldata?.fungsiGinjalUreum
                  ? ldata?.fungsiGinjalUreum
                  : 0,
                fungsiGinjalAsam: ldata?.fungsiGinjalAsam
                  ? ldata?.fungsiGinjalAsam
                  : 0,
                fungsiJantungABI: ldata?.fungsiJantungABI
                  ? ldata?.fungsiJantungABI
                  : 0,
                fungsiJantungEKG: ldata?.fungsiJantungEKG
                  ? ldata?.fungsiJantungEKG
                  : null,
                fungsiJantungEcho: ldata?.fungsiJantungEcho
                  ? ldata?.fungsiJantungEcho
                  : null,
                funduskopi: ldata?.funduskopi ? ldata?.funduskopi : null,
                pemeriksaanLain: ldata?.pemeriksaanLain
                  ? ldata?.pemeriksaanLain
                  : null,
                keterangan: ldata?.keterangan ? ldata?.keterangan : null,
              })

              setLclStorage(lname, JSON.stringify(ldata), {type: 2})
            } else {
              onChangeData({
                jenisRujukan: kdata?.kdkhusus ? 1 : 2,
              })
              // console.log('Data MCU Pcare tidak ditemukan')
            }
          })
          .catch(e => {
            // console.log(e, 'Gagal mengambil Data MCU Pcare')
          })
      }
    }
  }

  const addMcuPcare = async () => {
    const {yesNoRef, okRef, formRef} = tFormRef.current
    const uid = dctid
    const kdata = data
    const lname = 'dataBpjs_mcu'
    // const dataBpjs = getLclStorage(lname, {type: 2})
    // const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

    const handlerOk = () => {
      // Refetch data mcu
      loadMcuPcare()

      const elBtnHideMCU = getElement({
        equal: 'hide-mcu',
        tag: 'button',
      })
      if (elBtnHideMCU?.ariaLabel === 'hide-mcu') {
        const isShown = headmcu[0] === elBtnHideMCU?.innerText
        if (isShown) {
          elBtnHideMCU?.click()
        }
      }
      setentriMCU(2)
    }

    let entriMcu = {
      kdMCU: kdata?.kdMCU ? kdata?.kdMCU : 0,
      noKunjungan: kdata?.noKunjungan ? kdata?.noKunjungan : '',
      kdProvider: kdata?.kdProvider ? kdata?.kdProvider : '',
      tglPelayanan: kdata?.tglPelayanan
        ? moment(kdata?.tglPelayanan).format('DD-MM-YYYY')
        : '',
      tekananDarahSistole: kdata?.tekananDarahSistole
        ? kdata?.tekananDarahSistole
        : 0,
      tekananDarahDiastole: kdata?.tekananDarahDiastole
        ? kdata?.tekananDarahDiastole
        : 0,
      radiologiFoto: kdata?.radiologiFoto ? kdata?.radiologiFoto : null,
      darahRutinHemo: kdata?.darahRutinHemo ? kdata?.darahRutinHemo : 0,
      darahRutinLeu: kdata?.darahRutinLeu ? kdata?.darahRutinLeu : 0,
      darahRutinErit: kdata?.darahRutinErit ? kdata?.darahRutinErit : 0,
      darahRutinLaju: kdata?.darahRutinLaju ? kdata?.darahRutinLaju : 0,
      darahRutinHema: kdata?.darahRutinHema ? kdata?.darahRutinHema : 0,
      darahRutinTrom: kdata?.darahRutinTrom ? kdata?.darahRutinTrom : 0,
      lemakDarahHDL: kdata?.lemakDarahHDL ? kdata?.lemakDarahHDL : 0,
      lemakDarahLDL: kdata?.lemakDarahLDL ? kdata?.lemakDarahLDL : 0,
      lemakDarahChol: kdata?.lemakDarahChol ? kdata?.lemakDarahChol : 0,
      lemakDarahTrigli: kdata?.lemakDarahTrigli ? kdata?.lemakDarahTrigli : 0,
      gulaDarahSewaktu: kdata?.gulaDarahSewaktu ? kdata?.gulaDarahSewaktu : 0,
      gulaDarahPuasa: kdata?.gulaDarahPuasa ? kdata?.gulaDarahPuasa : 0,
      gulaDarahPostPrandial: kdata?.gulaDarahPostPrandial
        ? kdata?.gulaDarahPostPrandial
        : 0,
      gulaDarahHbA1c: kdata?.gulaDarahHbA1c ? kdata?.gulaDarahHbA1c : 0,
      fungsiHatiSGOT: kdata?.fungsiHatiSGOT ? kdata?.fungsiHatiSGOT : 0,
      fungsiHatiSGPT: kdata?.fungsiHatiSGPT ? kdata?.fungsiHatiSGPT : 0,
      fungsiHatiGamma: kdata?.fungsiHatiGamma ? kdata?.fungsiHatiGamma : 0,
      fungsiHatiProtKual: kdata?.fungsiHatiProtKual
        ? kdata?.fungsiHatiProtKual
        : 0,
      fungsiHatiAlbumin: kdata?.fungsiHatiAlbumin
        ? kdata?.fungsiHatiAlbumin
        : 0,
      fungsiGinjalCrea: kdata?.fungsiGinjalCrea ? kdata?.fungsiGinjalCrea : 0,
      fungsiGinjalUreum: kdata?.fungsiGinjalUreum
        ? kdata?.fungsiGinjalUreum
        : 0,
      fungsiGinjalAsam: kdata?.fungsiGinjalAsam ? kdata?.fungsiGinjalAsam : 0,
      fungsiJantungABI: kdata?.fungsiJantungABI ? kdata?.fungsiJantungABI : 0,
      fungsiJantungEKG: kdata?.fungsiJantungEKG
        ? kdata?.fungsiJantungEKG
        : null,
      fungsiJantungEcho: kdata?.fungsiJantungEcho
        ? kdata?.fungsiJantungEcho
        : null,
      funduskopi: kdata?.funduskopi ? kdata?.funduskopi : null,
      pemeriksaanLain: kdata?.pemeriksaanLain ? kdata?.pemeriksaanLain : null,
      keterangan: kdata?.keterangan ? kdata?.keterangan : null,
    }

    if (rdata?.kunpcarekunjungan) {
      await axios
        .post(
          `${APIBPJS}/bpjs-jkn/mcu-pcare`,
          QueryString.stringify({
            uid: uid,
            reg: 'dbdok',
            a: 'dk13',
            type: 'mcu',
            method: kdata?.kdMCU ? 'PUT' : 'POST',
            data: entriMcu,
          })
        )
        .then(({data}) => {
          // console.log(data, 'add-mcu-pcare')
          // localStorage.setItem('add-mcu-pcare', JSON.stringify(data))
          if (
            data?.metaData?.message?.toLocaleLowerCase().includes('created') ||
            data?.metaData?.message?.toLocaleLowerCase().includes('ok')
          ) {
            let kdMCU =
              data.response &&
              data.response.length > 0 &&
              data?.response[0]?.field == 'kdMCU'
                ? `${data.response[0].message}`
                : kdata?.kdMCU

            // localStorage.setItem('kdMCU', JSON.stringify(kdMCU))

            okRef.show(
              {
                title: 'Berhasil',
                message: 'Data Entri MCU berhasil disimpan.',
                type: 'success',
              },
              handlerOk,
              handlerOk
            )

            // console.log('kdMCU', kdMCU)
          } else {
            let countRes = data.response ? data.response.length : 1
            var message =
              data.response && data.response.length > 0
                ? `${data.response[0].field} ${data.response[0].message}`
                : ''
            let metadataMessage =
              data.metaData && data.metaData.message
                ? data.metaData.message
                : ''

            if (countRes > 1) {
              let mergedMessages = ''
              data.response.forEach(item => {
                mergedMessages += `${item.field} ${item.message}, `
              })
              message = mergedMessages.trim()
            }

            if (!message && metadataMessage) {
              message = metadataMessage
            }

            message = message
              ? message
              : `Gagal menyimpan Data MCU PCare dengan No. Kunjungan ${rdata?.kunpcarekunjungan}`

            // console.log('MCU message', message)
            okRef.show(
              {
                message: message,
              },
              () => {},
              () => {}
            )
          }
        })
        .catch(e => {
          // console.log('MCU message', '[CP1] Gagal menyimpan Data MCU Pcare')
          okRef.show(
            {
              message: '[CP1] Gagal menyimpan Data MCU Pcare',
            },
            () => {},
            () => {}
          )
        })
    }
  }

  const loadObatPcare = () => {
    if (rdata?.kunpcarekunjungan) {
      mGetObatPcare({
        input: {data: {noKunjungan: rdata?.kunpcarekunjungan}, uid: dctid},
      })
        .then(async ({data}) => {
          if (data?.metaData?.code == 200) {
            if (data?.response?.count > 0) {
              const nwlist = await Promise.all(
                data?.response?.list?.map(async im => {
                  var dobt = await mSearchObatPcare({
                    input: {
                      id: dctid,
                      limit: 1,
                      offset: 0,
                      carimobile: im?.obat?.kdObat,
                    },
                  })
                  if (dobt?.data?.data?.length > 0) {
                    dobt = dobt.data.data[0]
                  } else {
                    dobt = im?.obat || {}
                  }
                  return {
                    ...im,
                    ...dobt,
                    qty: im.jmlObat,
                    stok: dobt?.sedia || 0,
                    flagcart: generateFlags(dobt, fieldkey),
                  }
                })
              )
              setcarts({list: nwlist || []})
              setprevcarts(nwlist || [])
            }
          } else {
            // console.log('Data Obat Pcare tidak ditemukan')
          }
        })
        .catch(e => {
          // console.log(e, 'Gagal mengambil Data Obat Pcare')
        })
    }
  }

  useEffect(() => {
    for (var key in localStorage) {
      if (`${key}`?.match(/^PEC-/i)) {
        if (key !== optcarts.name) {
          localStorage.removeItem(key)
        }
      }
    }
    return () => {
      for (var key in localStorage) {
        if (`${key}`?.match(/^PEC-/i)) {
          if (key !== optcarts.name) {
            localStorage.removeItem(key)
          }
        }
      }
    }
  }, [optcarts])

  useEffect(() => {
    // Cek Custom BPJS PCare
    cekCustomBpjsPcare()
    return () => {}
  }, [dctid])

  useEffect(() => {
    // console.log('useEffect Triggered gan', [listForm, dctid, pid, kid, id])
    // console.log(soaplist, 'soaplist');

    if (listForm === null && dctid && pid && kid) {
      getKunjungan()
    } else {
      if (listForm === null && (!dctid || !pid || !kid)) {
        navigate('/404')
      }
    }
    var timeout = setTimeout(() => {
      const elBtnHideOptions = getElement({
        equal: 'hide-options',
        tag: 'button',
      })
      if (elBtnHideOptions?.ariaLabel === 'hide-options') {
        const isShown = optionaltitles[0] === elBtnHideOptions?.innerText
        if (isShown) {
          elBtnHideOptions?.click()
        }
      }
      const elBtnHideMCU = getElement({
        equal: 'hide-mcu',
        tag: 'button',
      })
      if (elBtnHideMCU?.ariaLabel === 'hide-mcu') {
        const isShown = headmcu[0] === elBtnHideMCU?.innerText
        if (isShown) {
          elBtnHideMCU?.click()
        }
      }
      if (timeout) {
        clearTimeout(timeout)
      }
      const elBtnHideCS = getAllElement({
        equal: 'hide-card-sections',
        tag: 'button',
      })
      if (elBtnHideCS?.length > 0) {
        for (let i = 0; i < elBtnHideCS?.length; i++) {
          const el = elBtnHideCS[i]
          const isShown = headopttitles[0] === el?.innerText
          if (isShown) {
            el?.click()
          }
        }
      }
      if (timeout) {
        clearTimeout(timeout)
      }
    }, 500)
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line
  }, [listForm, dctid, pid, kid, id])
  // console.log('listForm', dctid, pid, id)

  useEffect(() => {
    // Get Data BPJS PCare
    if (customPcare) {
      // if(data?.keluhanutama == '' || data?.keluhanutama == undefined)
      loadPendaftaranPcare()
      loadRujukanPcare()
      loadDataDokter()
      loadKesadaran()
      loadStatusPulang()
      loadDataSpesialis()
      loadDataSarana()
      loadDataKhusus()
      loadMcuPcare()
      loadObatPcare()
    }
    return () => {}
  }, [data])

  const onTabChange = (item, i) => {
    setActiveTab(item.value)
    // console.log(listForm);return

    // Set Hide / Show Template Form -nya
    // let clonedListForm = structuredClone(listForm)
    // let clonedListForm = JSON.parse(JSON.stringify(listForm))
    let clonedListForm = listForm
    // console.log('before alteration', clonedListForm)
    if (soaplist.includes(item.value)) {
      // Khusus SOAP
      let otherThanSelected = soaplist.filter(x => x !== item.value)
      for (let i = 0; i < otherThanSelected.length; i++) {
        // Set invisible content yang lain
        clonedListForm[otherThanSelected[i]].visible = false
      }
      // console.log('after alteration', clonedListForm)
      clonedListForm[item.value].visible = true // Set visible content yang baru saja dipilih

      // Hide 3 Section (Perawatan/Tindakan, Resep Dokter, Rujukan), dia masuk di children
      clonedListForm.children = () => <></>
      // console.log(clonedListForm)

      /**
       * Karena ini casenya beda-beda jadi digunakan switch untuk menghandle kondisionalnya
       */
      let disableAll = !editable || disabled || rdata?.status == 4
      const [containerClassName, titleClassName, classItem, classSparator] =
        defaultClassNames
      const attrOpt = {
          propsInput: {
            disabled: disableAll,
            readOnly: disableAll || true,
          },
        },
        attrTextArea = {
          typeInput: 'textarea',
          Pascal: '-',
          propsInput: {
            disabled: disableAll,
            readOnly: disableAll,
            className: 'h-20 resize-none',
          },
        }
      switch (item.value) {
        case 'subjective':
          // Perbarui default value agar mengambil value yang terakhir (agar tidak diset kembali ke value initial)
          clonedListForm.subjective['keluhan-utama'].inputCustom.value =
            data['keluhanutama']
          clonedListForm.subjective['keluhan-tambahan'].inputCustom.value =
            data['keluhantambahan']
          break

        case 'plan':
          // console.log('masuk case plan', attrTextArea)
          clonedListForm.plan.children = index => {
            const key = `C-plan-${index}`
            const childData = {
              'terapi-obat': {
                inputCustom: {
                  ariaLabel: 'terapi',
                  value: data['terapi'] ?? '',
                  ...attrTextArea,
                },
              },
              'saran-/-anjuran': {
                inputCustom: {
                  ariaLabel: 'anjuran',
                  value: data['anjuran'] ?? '',
                  ...attrTextArea,
                },
              },
            }

            let alreadyLocked = data?.lock && rdata?.status == 4
            const msg = alreadyLocked
              ? 'Rekam Medis Terkunci'
              : disableAll
              ? 'Rekam Medis Belum Terkunci'
              : 'Kunci Rekam Medis'

            return (
              <Fragment key={key}>
                <VmView className={classNames(classSparator, '')} />
                <VmView
                  className={
                    childData?.containerClassName || containerClassName
                  }
                >
                  <VmView
                    className={classNames(
                      childData?.titleClassName || titleClassName,
                      'flex justify-between items-center'
                    )}
                  >
                    <VmText className="">Plan</VmText>
                    {/* <VmButton
                      label="hide-card-sections"
                      rounded="full"
                      className="btn btn-info px-4 !bg-transparent"
                    >
                      {headopttitles[0]}
                    </VmButton> */}
                  </VmView>
                  {Items(childData, key)}
                  <VmView
                    className="flex items-center m-4 mb-5"
                    style={{color: '#64748b'}}
                    label="lock"
                  >
                    {alreadyLocked ? (
                      <VmIcons
                        name={'VmLockClosedIcon'}
                        color={rgbcolors.secondary}
                        size={30}
                        variant="outline"
                      />
                    ) : disableAll ? (
                      <VmIcons
                        name={'VmLockOpenIcon'}
                        color={rgbcolors.secondary}
                        size={30}
                        variant="outline"
                      />
                    ) : (
                      <VmView className="flex">
                        <VmInput
                          {...{
                            label: `suffix-checkbox-lock`,
                            typeInput: 'checkbox',
                            defaultChecked: data?.lock,
                            className: 'w-[30px] h-[30px]',
                            id: 'checkbox-lock',
                          }}
                        />
                      </VmView>
                    )}
                    <VmText
                      typeText="label"
                      htmlFor="checkbox-lock"
                      className="pl-2"
                    >
                      {msg}
                    </VmText>
                  </VmView>
                  <VmView
                    className="flex items-center m-4 mb-5"
                    style={{color: '#64748b'}}
                  >
                    {customPcare && (
                      <>
                        <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex mb-2">
                          <VmText>Entri Kunjungan PCare</VmText>

                          <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                            <div className="col-md-9 mt-2">
                              <div className="custom-radio">
                                {[1, 2].map(value => (
                                  <label
                                    key={value}
                                    style={{fontWeight: 'unset'}}
                                    className="mr-4"
                                  >
                                    <input
                                      aria-label="entripcare"
                                      id={`entripcare${value}`}
                                      type="radio"
                                      // className="input_radio_entri_pcare"
                                      value={value}
                                      name="entriPcare"
                                      style={{marginRight: '5px'}}
                                      checked={data?.entripcare == value}
                                      disabled={data?.entripcaredisable}
                                      onChange={handleEntriPcare}
                                    />
                                    {value === 1 ? 'Ya' : 'Tidak'}
                                  </label>
                                ))}
                              </div>
                            </div>
                          </VmView>
                        </VmView>
                      </>
                    )}
                  </VmView>
                </VmView>
              </Fragment>
            )
          }
          break

        case 'assesment':
          // console.log('masuk case assesment', attrTextArea)
          const AssesmentData = {
            'diagnosa-utama': {
              inputCustom: {
                required: true,
                ariaLabel: 'diagnosa',
                value: data['diagnosa'] ?? '',
                items: optdiagnosis,
                placeholder: 'Pilih diagnosa',
                placeholderFilter: 'Cari Kode / Nama Diagnosa',
                className: 'flex-col',
                propsInput: {
                  ...attrOpt.propsInput,
                  readonly: true,
                },
                suffix: () =>
                  disableAll ? (
                    <></>
                  ) : (
                    <VmInput
                      {...{
                        label: `suffix-btn-diagnosa`,
                        typeInput: 'button',
                        style: {
                          color: data['diagnosa']
                            ? rgbcolors['danger']
                            : rgbcolors['primary'],
                        },
                        defaultValue: data['diagnosa'] ? 'ⓧ' : '▼',
                      }}
                    />
                  ),
              },
            },
            'diagnosa-sekunder': {
              inputCustom: {
                ariaLabel: 'icdid',
                value: data['icdid'] ?? '',
                items: optdiagnosis,
                placeholder: 'Pilih diagnosa',
                placeholderFilter: 'Cari Kode / Nama Diagnosa',
                className: 'flex-col',
                propsInput: {
                  ...attrOpt.propsInput,
                  readonly: true,
                },
                suffix: () =>
                  disableAll ? (
                    <></>
                  ) : (
                    <VmInput
                      {...{
                        label: `suffix-btn-diagnosa`,
                        typeInput: 'button',
                        style: {
                          color: data['icdid']
                            ? rgbcolors['danger']
                            : rgbcolors['primary'],
                        },
                        defaultValue: data['icdid'] ? 'ⓧ' : '▼',
                      }}
                    />
                  ),
              },
            },
            'hasil-pemeriksaan': {
              inputCustom: {
                ariaLabel: 'hasil',
                value: data['hasil'] ?? '',
                ...attrTextArea,
              },
            },
          }
          // Replace children-nya, agar mendapatkan default value dari value yang terakhir (setelah melakukan peng-editan)
          clonedListForm.assesment.children = index => {
            const key = `C-Assesment-${index}`
            const childData = AssesmentData
            return (
              <Fragment key={key}>
                <VmView className={classNames(classSparator, '')} />
                <VmView
                  className={
                    childData?.containerClassName || containerClassName
                  }
                >
                  <VmView
                    className={classNames(
                      childData?.titleClassName || titleClassName,
                      'flex justify-between items-center'
                    )}
                  >
                    <VmText className="">Assesment</VmText>
                    {/* <VmButton
                      label="hide-card-sections"
                      rounded="full"
                      className="btn btn-info px-4 !bg-transparent"
                    >
                      {headopttitles[0]}
                    </VmButton> */}
                  </VmView>
                  {Items(childData, key)}
                </VmView>
              </Fragment>
            )
          }
          break

        case 'objective':
          clonedListForm.objective['row1'].items.nadi.inputCustom.value =
            data['nadi'] ?? ''
          clonedListForm.objective['row1'].items.suhu.inputCustom.value =
            data['suhu'] ?? ''
          clonedListForm.objective.pernafasan.inputCustom.value =
            data['nafas'] ?? ''
          clonedListForm.objective['lingkar-perut'].inputCustom.value =
            data['perut'] ?? ''
          clonedListForm.objective['row2'].items[
            'tek.-darah-(systolic)'
          ].inputCustom.value = data['tekanandarah_systolic'] ?? ''
          clonedListForm.objective['row2'].items[
            'tek.-darah-(diastolic)'
          ].inputCustom.value = data['tekanandarah_diastolic'] ?? ''
          clonedListForm.objective['row3'].items[
            'tinggi-badan'
          ].inputCustom.value = data['tinggi'] ?? ''
          clonedListForm.objective['row3'].items[
            'berat-badan'
          ].inputCustom.value = data['berat'] ?? ''
          clonedListForm.objective['b'][''].kepala.rambut.inputCustom.value =
            data['rambut'] ?? ''
          clonedListForm.objective['b'][''].mata.pupil.inputCustom.value =
            data['matapupil'] ?? ''
          clonedListForm.objective['b'][''].mata[
            'sclera-:-icteric'
          ].inputCustom.value = data['matasclera'] ?? ''
          clonedListForm.objective['b'][''].telinga[
            'membrane-thymp'
          ].inputCustom.value = data['telingamembran'] ?? ''
          clonedListForm.objective['b'][''].telinga[
            'cerumen-plug'
          ].inputCustom.value = data['telingacerumen'] ?? ''
          clonedListForm.objective['b'][''].hidung[
            'polip-nasi'
          ].inputCustom.value = data['hidungpolip'] ?? ''
          clonedListForm.objective['b'][''].hidung[
            'deviasi-septum'
          ].inputCustom.value = data['hidungdeviasi'] ?? ''
          clonedListForm.objective['b'][''].tenggorokan[
            'Pembesaran-KGB'
          ].inputCustom.value = data['tengggorokan'] ?? ''
          clonedListForm.objective['b'][''].gigi['carries'].inputCustom.value =
            data['gigicaries'] ?? ''
          clonedListForm.objective['b'][''].gigi[
            'kelainan-lainnya'
          ].inputCustom.value = data['gigikelainan'] ?? ''
          clonedListForm.objective['b'][''].leher[
            'pembesaran-KGB'
          ].inputCustom.value = data['leher'] ?? ''

          // C

          clonedListForm.objective['c']['thorax'].jantung[
            'HR'
          ].inputCustom.value = data['jantunghr'] ?? ''
          clonedListForm.objective['c']['thorax'].jantung[
            'bunyi-jantung'
          ].inputCustom.value = data['jantungbunyi'] ?? ''
          clonedListForm.objective['c']['thorax'].jantung[
            'mur-mur'
          ].inputCustom.value = data['jantungmur'] ?? ''

          clonedListForm.objective['c']['thorax'].paru[
            'vesikuler'
          ].inputCustom.value = data['paruvesikuler'] ?? ''
          clonedListForm.objective['c']['thorax'].paru[
            'ronchi'
          ].inputCustom.value = data['paruronchi'] ?? ''

          // e
          clonedListForm.objective['e']['abdomen'].palpasi[
            'nyeri-tekan'
          ].inputCustom.value = data['palpasinyeri'] ?? ''
          clonedListForm.objective['e']['abdomen'].palpasi[
            'benjolan'
          ].inputCustom.value = data['palpasibenjol'] ?? ''
          clonedListForm.objective['e']['abdomen'].palpasi[
            'hepar'
          ].inputCustom.value = data['palpasihepar'] ?? ''
          clonedListForm.objective['e']['abdomen'].palpasi[
            'lien'
          ].inputCustom.value = data['palpasilien'] ?? ''

          clonedListForm.objective['e']['abdomen'].auskultasi[
            'bising-usus'
          ].inputCustom.value = data['auskultasi'] ?? ''

          // F
          clonedListForm.objective['f']['ekstremitas'].atas[
            'kelainan'
          ].inputCustom.value = data['ataskelainan'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].atas[
            'kebersihan-kuku'
          ].inputCustom.value = data['ataskuku'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].atas[
            'refleks-fisiologis'
          ].inputCustom.value = data['atasfisio'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].atas[
            'refleks-patologis'
          ].inputCustom.value = data['ataspato'] ?? ''

          clonedListForm.objective['f']['ekstremitas'].bawah[
            'kelainan'
          ].inputCustom.value = data['bawahkelainan'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].bawah[
            'kebersihan-kuku'
          ].inputCustom.value = data['bawahkuku'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].bawah[
            'refleks-fisiologis'
          ].inputCustom.value = data['bawahfisio'] ?? ''
          clonedListForm.objective['f']['ekstremitas'].bawah[
            'refleks-patologis'
          ].inputCustom.value = data['bawahpato'] ?? ''

          clonedListForm.objective['f']['ekstremitas'].kulit[
            'kelainan'
          ].inputCustom.value = data['kulit'] ?? ''

          break
        case 'pcare':
          //set data
          break
        default:
          break
      }
      setListForm(clonedListForm)
    } else {
      // Untuk Perawatan/Tindakan, Resep Dokter, Rujukan (karena strukturnya beda)

      // Yang SOAP sudah pasti di-hide
      soaplist.forEach(v => {
        // Set invisible content yang lain
        clonedListForm[v].visible = false
      })
      clonedListForm.children = () => {
        const showFaktur = [3, 4, 1].includes(Number(rdata?.status))

        return (
          <Fragment>
            {/* Faktur */}
            {showFaktur && (
              <FakturRm
                kid={data?.kid || kid}
                uid={data?.uid || dctid}
                pid={data?.pid || pid}
                rmid={data?.id}
                rdata={rdata}
                tindakanVisible={item.value === 'tindakan'}
                resepVisible={item.value === 'resep'}
                rujukanVisible={item.value === 'rujukan'}
                lock={Number(rdata?.status) === 4}
                // editable={editable}
                disabled={!editable}
                mode={mode}
              />
            )}
            {/* Pcare */}

            {customPcare && item.value == 'pcare' && (
              <PcareExamination
                uid={dctid}
                pid={data?.pid}
                kid={data?.kid}
                rmid={data?.id}
                mode={mode}
                pcareVisible={item.value === 'pcare' && customPcare}
                rdata={rdata}
                kdata={data}
                optcarts={optcarts}
                onChangeData={onChangeData}
                disabled={disabled ? disabled : data['lock'] || false}
                fieldkey={fieldkey}
                headmcu={headmcu}
              />
            )}
          </Fragment>
        )
      }
      setListForm(clonedListForm)
    }
  }

  return (
    <>
      {headerAttr?.enable && <VmHeader {...headerAttr} />}
      <VmView className="pb-6" id="root-form">
        {errorinitial ? (
          <FullErrorView message={errormessage} />
        ) : (
          <>
            {/* Card Informasi Pasien */}
            <PatientInformation
              pathname={pathname}
              controllers={controllers}
              navigate={navigate}
              queryParams={queryParams}
              getDetailMedicalRecord={getDetailMedicalRecord}
              getMedicalRecords={getMedicalRecords}
              datamedicalrecord={datamedicalrecord}
              currentrm={datacurrentmedicalrecord}
              id={id}
              data={pdata}
              avatar={avatar}
              loadingrm={loadingrm}
              mode={mode}
            />

            {/* <h1>This is text in between Guys</h1> */}
            {mode == 'web' ? (
              <VmView
                id="tab-container"
                className="flex items-center mx-4 my-6 gap-2"
              >
                {(() => {
                  let sudah_diperiksa = [3, 4].includes(Number(rdata?.status))
                  let list_section = [
                    {value: 'subjective', text: 'Subjective'},
                    {value: 'objective', text: 'Objective'},
                    {value: 'assesment', text: 'Assesment'},
                    {value: 'plan', text: 'Plan'},
                    {value: 'resep', text: 'Resep Dokter'},
                  ]

                  if (sudah_diperiksa) {
                    list_section = [
                      ...list_section,
                      {value: 'tindakan', text: 'Perawatan / Tindakan'},
                      {value: 'rujukan', text: 'Rujukan'},
                    ]
                  }

                  if (customPcare) {
                    list_section = [
                      ...list_section,
                      {value: 'pcare', text: 'Pemeriksaan Pcare'},
                    ]
                  }
                  return list_section.map((item, i) => {
                    return (
                      <VmButton
                        key={i}
                        onClick={() => onTabChange(item, i)}
                        variant={activeTab == item.value ? '' : 'ghost'}
                        className={'p-4 font-bold'}
                      >
                        {item.text}
                      </VmButton>
                    )
                  })
                })()}
              </VmView>
            ) : null}

            {/* Form SOAP, Perawatan/Tindakan, Resep Dokter, Rujukan */}
            <TemplateForm
              ref={tFormRef}
              loading={loading}
              listForm={listForm}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              onChangeCapture={onChangeCapture}
              onClick={onClick}
            >
              {editable && (
                <>
                  <PatExamFooters
                    {...Object.assign(!!listForm ? footers : {}, {})}
                  />
                </>
              )}
            </TemplateForm>
          </>
        )}
      </VmView>
    </>
  )
}

export default PatientExamination
