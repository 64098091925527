import moment from 'moment'
import 'moment/locale/en-sg'

class PcareDataEntriKun {
  constructor(props = {}) {
    const lstjk = {L: 'Laki-laki', P: 'Perempuan'}
    const faskesRujuk = props?.providerRujukLanjut?.nmProvider ? `${props?.providerRujukLanjut?.nmProvider}` : ''
    // No Kunjungan
    this.noKunjungan = props?.noKunjungan || ''
    // Tanggal Pelayanan
    this.tglPelayanan = props?.tglKunjungan
      ? moment(
          `${props?.tglKunjungan}`.trim().split(/\-/).reverse().join('-')
        ).format('DD MMM YYYY')
      : ''
    // Tanggal Entri
    this.tglEntri = props?.tglKunjungan
      ? moment(
          `${props?.tglKunjungan}`.trim().split(/\-/).reverse().join('-')
        ).format('DD MMM YYYY')
      : ''
    // Nama Peserta
    this.nama = props?.peserta?.nama || ''
    // No Kartu
    this.noKartu = props?.peserta?.noKartu || ''
    // Jenis Kelamin
    this.jk = props?.peserta?.sex
      ? lstjk[`${props?.peserta?.sex}`.toUpperCase()] || props?.peserta?.sex
      : ''
    // Diagnosa
    this.diagnosa = props?.diagnosa1?.nmDiag || ''
    // Dirujuk
    this.dirujuk = faskesRujuk

    this.defaultdata = props || {}
  }
}
export {PcareDataEntriKun}
